.header {
    padding-left: 27px;
    border-top-right-radius: 6px;
    background-color: #004489;
}

.header .icons {
    text-align: right;
    padding-right: 16px;
}

.header .title {
    text-transform: uppercase;
    font-size: 1.5rem;
    white-space: nowrap;
}

.cells {
    display: table;
    width: 100%;
    height: 48px;
}

.cell {
    display: table-cell;
    vertical-align: middle;
}

.minimised.header {
    padding-left: 16px;
}

.header-title {
    display: block;
}
.mobile-header-title {
    display: none;
}

@media only screen and (max-device-width: 1024px) and (orientation: portrait) {
    .header .icons {
        display: none;
    }
    .mobile-header-title {
        display: block;
    }
    .header-title {
        display: none;
    }
    .header {
        border-top-right-radius: unset;
        background-color: unset;
        padding: 4%;
    }

    .header .close {
        display: block;
        width: 27px;
        height: 22px;
        background-image: url(/img/mobile/menu-close.png);
        margin-bottom: 24px;
        min-width: 27px;
        background-size: contain;
        background-position: 50% 50%;
        background-repeat: no-repeat;
    }

    .header .title {
        background-color: #004489;
        border-color: #004489;
        display: block;
        width: 100%;
        font-size: 1.8rem;
        padding: 8px;
        text-align: center;
        text-transform: capitalize;
    }
    .cells {
        height: 0px;
    }
}