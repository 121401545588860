.gallery {
    display: flex;
    position: relative;
    /* max-width: 290px;*/
}

.slides {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    /* align-items: flex-end; */
    justify-content: center;
    width: 100%;
}

.slide {
    width: 100%;
}

.image-status {
    position: absolute;
    top: 0;
    right: 4px;
    padding: 4px;
    font-size: 1.4rem;
    text-shadow: 1px 1px 1px rgb(0 0 0 / 90%);
    color: #fff;
}

.gallery img {
    object-fit: contain;
}

[class*="main-sale-video-panel"] [class*="auction-media"] .gallery img {
    height: 182px;
    width: 242px;
}

.left [class*="auction-sale-container"] .gallery img {
    height: 240px;
    object-fit: unset;
}

@media only screen and (max-width: 476px) {

    [class*="auction-media"] .gallery img {
        height: 155px;
        width: 235px;
    }
}

@media only screen and (min-width: 744px) and (max-width: 1023px) {
    [class*="auction-media"] .gallery img {
        height: 330px;
        width: 440px;
    }
}

@media only screen and (min-width: 750px) and (max-width: 1024px) and (orientation: portrait) {
    .image-status {
        right: 4rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1365px) and (orientation: landscape) {
    [class*="main-sale-video-panel"] [class*="auction-media"] .gallery img {
        height: auto;
        width: 100%;
        padding: 2px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1112px) {
    [class*="main-sale-video-panel"] .gallery img {
        height: 440px;
        width: 580px;
    }
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {

    [class*="main-sale-video-panel"] [class*="auction-media"] .gallery img {
        height: 190px;
        width: 260px;
    }
}

@media only screen and (min-width: 1600px) and (max-width: 1679px) {

    [class*="main-sale-video-panel"] [class*="auction-media"] .gallery img {
        height: 230px;
        width: 305px;
    }
}

@media only screen and (min-width: 1680px) and (max-width: 1899px) {
    [class*="main-sale-video-panel"] [class*="auction-media"] .gallery img {
        height: 242px;
        width: 320px;
    }
}

@media only screen and (min-width: 1900px) and (max-width: 2500px) {
    [class*="main-sale-video-panel"] [class*="auction-media"] .gallery img {
        width: 100%;
        min-height: 250px;
        height: auto;
        max-height: 330px;
    }
}