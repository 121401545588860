.notify-badges {
    position: relative;
}

    .notify-badges .badge {
        display: inline-block;
        width: 18px;
        height: 20px;
        margin-right: 3px;
        background-position: 50% 0;
        background-repeat: no-repeat;
        background-size: 105%;
    }

[class*='lot-header-lights'] .notify-badges .badge {
    width: 22px;
    height: 22px;
}

[class*=badge-list] .notify-badges .badge {
    margin-right: 8px;
}

.notify-badges .announcements.badge {
    background-image: url(img/icon-announcements.png);
    color: #54a72e;
    text-align: left;
}

.notify-badges .remarks.badge {
    background-image: url(img/icon-remarks.png);
    color: #ec9d00;
    text-align: center;
}

.notify-badges .watch.badge {
    background-image: url(img/icon-watch.png);
    color: #4fb0e5;
    text-align: right;
}

.notify-badges .badge .label {
    visibility: hidden;
    position: absolute;
    top: 17px;
    left: 0;
    width: 100%;
    font-size: 0.9rem;
    width: 50px;
    line-height: 100%;
}

.notify-badges .badge:hover .label {
    visibility: visible;
}

@media only screen and (min-width:1600px) {
    [class*='lot-header-lights'] .notify-badges .badge {
        width: 36px;
        height: 36px;
    }

    .notify-badges .badge .label {
        top: 31px;
    }
}

@media only screen and (min-width:1024px) and (orientation:landscape) {
    [class*=main] .notify-badges {
        padding-left: 15px;
    }
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
    [class*='grid-item-sale-header'] .notify-badges .badge .label {
        left: unset;
        right: 0;
    }
}
