.header-icons {
    display: flex !important;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    column-gap: 16px;
}

.logo-div {
    @extend .header-icons;
    width: 90%;
}

.btn-reconnect {
    display: inline-flex;
    position: absolute;
    left: 50%;
    top: 8px;
    transform: translate(-50%, 0);
    flex-wrap: nowrap;
    align-items: center;
    background-color: #b00202;
    color: white;
    font-size: 16px;
    border-radius: 5px;
    -webkit-box-shadow: 2px 2px 8px 2px #000000;
    box-shadow: 2px 2px 8px 2px #000000;
    padding: 0 4px;
    cursor: pointer;
}

.btn-reconnect .icon {
    color: #fff;
}

.nav-header {
    padding: 0 36px;
    position: sticky;
    top: 0;
    background-color: #fff;
    box-shadow: 0 2px 6px 2px rgb(0 0 0 / 4%);
    width: 100%;
    z-index: 9999;
}

.nav-header .logo {
    max-height: 50px;
    width: auto;
}

.nav-header .cells {
    height: 60px;
    display: table;
    width: 100%;
}

.nav-header .cell {
    display: table-cell;
    vertical-align: middle;
}

.nav-header .secondary.cells {
    width: auto;
    display: inline-table;
}

.mobile-menu-trigger {
    display: none;
    position: absolute;
    top: 0;
    left: 6%;
    margin-bottom: 0;
    height: 100%;
    width: 5%;
    min-width: 27px;
    background-image: url(/img/mobile-menu.svg);
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.auction-result-button {
    display: inline-block;
    padding: 3px 10px;
    color: #fff;
    background-color: #087bbc;
    border-color: #087bbc;
    border-radius: 20px;
    margin-right: 10px;
    cursor: pointer;
}

.nav-header .right.cell {
    text-align: right;
}

@media only screen and (max-width: 1400px) {
    .nav-header .cells {
        height: 45px;
    }
}


@media only screen and (max-width: 1024px) and (orientation: portrait),
                       (max-width: 1024px) and (max-aspect-ratio: 13/9) {
    .nav-header {
        padding: 0;
        height: auto;
        position: relative;
        margin-bottom: 0 !important;
    }

    .nav-header .cells,
    .nav-header .cell {
        display: block;
    }

    .nav-header .cell {
        padding: 1.25% 3%;
    }

    .nav-header .cells {
        height: auto;
    }

    .nav-header .right.cell {
        display: none;
    }

    .nav-header .left.cell {
        text-align: right;
    }

    .mobile-menu-trigger {
        display: block;
    }
}

@media only screen and (max-device-width: 960px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    .orientation-hide {
        display: none !important;
    }

    .clsMobileView .cls-reconnect-button {
        display: none;
    }
}

@media (max-height: 700px), only screen and (max-width: 374px) {
    .nav-header .logo {
        max-height: 29px;
    }
}