$colorRed: #e04242;
$colorRedHover: #ee2626;
$colorBlue: #314489;
$colorLinkBlue: #0000ee;

.main-center {
    display: flex;
    margin: 4% 8% 10% 8%;
    flex-direction: column;
    justify-content: center;
}

.heading-basic {
    text-align: left;
}

.heading1 {
    @extend .heading-basic;
    font-size: 2rem;
}

.main-center [class*="heading-component"] {
    @extend .heading-basic;
    font-size: 1.8rem;
    margin: 16px 0;
}

.contents {
    font-size: 1.5rem;
    line-height: 1.5;
}

.inner-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.inner-form button {
    width: fit-content;
    padding: 4px 8px;
    color: #fff;
    font-weight: 600;
    line-height: 36px;
    font-size: 2.2rem;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}
.accept-button {
    background-color: #56a832;
    border-color: #56a832;
}

.accept-button:hover {
    background-color: #46951b;
    border-color: #46951b;
}
.reject-button {
    background-color: $colorRed;
    border-color: $colorRed;
}

.reject-button:hover {
    background-color: $colorRedHover;
    border-color: $colorRedHover;
}

.link {
    text-decoration: underline;
    color: $colorLinkBlue;
    text-decoration-color: $colorLinkBlue;
}

.lozenge {
    border-radius: 20px;
    display: inline-block;
    border-width: 2px;
    border-style: solid;
}