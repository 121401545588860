.welcome_screen 
{
    width: 100%;
    height: 100%;
    display: block;
}

.welcome_screen .wrapper 
{
    display: table;
    width: 100%;
    height: 100%;
    max-width: 850px;
    padding-left: 90px;
}

.welcome_screen .wrapper .inner 
{
    display: table-cell;
    vertical-align: middle;
}

.welcome_screen [class*="heading-component"][class*="extra-large"] 
{
    font-weight: 400;
    margin: 0 0 30px 0;
    padding: 0;
}

.welcome_screen [class*="heading-component"][class*="medium"]
{
    margin: 0 0 18px 0;
    padding: 0;
}
