.summary {
    display: flex;
    flex-direction: column;
    margin: 5px;
    gap: 5px;

    .lane {
        display: flex;
        font-size: 14px;
    }

    .join-sale {
        display: flex;

        .swap-sale-image {
            display: flex;
            position: relative;

            .swap-sale-arrow {
                display: flex;
                position: absolute;
                width: 20px;
                top: 8px;
                left: 6px;
                transition: 0.3s;
            }

            &:hover > .swap-sale-arrow {
                left: 10px;
            }

            .swap-sale {
                display: flex;
                background-color: white;
                border-radius: 7px;
                padding: 2px;
            }
        }
    }

    .timestamp {
        display: flex;
    }
}

.sale-summary {
    padding-left: 27px;
}

.sale-summary {
    padding-top: 18px;
    padding-bottom: 18px;
}

.sale-summary .summary-lane:before {
    content: ' - ';
}

.sale-summary .line {
    max-width: 240px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.sale-summary .line-1 {
    font-size: 1.4rem;
    margin-bottom: 5px;
    opacity: 0.9;
    line-height: 19px;
}

.sale-summary .line-2 {
    font-size: 1.4rem;
    color: #cecece;
    opacity: 0.9;
    margin-bottom: 15px;
    line-height: 19px;
}

.sale-summary .line-3 {
    font-size: 1.2rem;
    opacity: 0.5;
}

.minimised {
    padding-left: 16px;
}

.minimised .line {
    overflow: visible;
}

.minimised .line-1 {
    font-size: 1.4rem;
    line-height: 145%;
    padding: 6px 0 30px 0;
}

.minimised .line-2,
.minimised .line-3 {
    display: none;
}

.minimised .summary-lane,
.minimised .summary-name {
    display: block;
}

.minimised .summary-name {
    max-width: 89px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.minimised .summary-lane:before {
    content: '';
}

@media only screen and (max-width:1025px) and (orientation:portrait) {
    .sale-summary {
        padding: 12px;
    }

    .sale-summary .line-1 {
        margin-bottom: 6px;
    }

    .sale-summary .line-2 {
        margin: 14px 0 6px 0px;
    }

    .minimised .line-1 {
        font-size: 1.2rem;
    }
}

@media only screen and (max-width:1024px) and (orientation:landscape) {
    .summary .timestamp {
        word-break: break-word;
    }
}
