.live-chat {
    position: relative;
    padding-bottom: 55px;
    height: 100%;
}

.live-chat .scrollbar-v {
    height: 100%;
    overflow-y: auto;
}

.chat-messages {
    padding: 15px 0;
    height: 100%;
}

.chat-message {
    display: block;
    margin-bottom: 4px;
    padding: 4px 10px;
    border-radius: 4px;
    width: fit-content;
    max-width: 90%;
}

.chat-message:last-child {
    margin-bottom: 0;
}

.chat-message .name {
    font-size: 1.4rem;
    margin-bottom: 5px;
    font-weight: 600;
}

.chat-message .message {
    text-align: left;
    font-size: 1.2rem;
    line-height: 145%;
    word-wrap: break-word;
}

.chat-message.operator {
    color: #087bbc;
    text-align: left;
    background-color: #f2f2f2;
}

.chat-message.caller {
    color: #7e838b;
    margin-left: auto;
    background-color: rgb(232, 235, 250);
}

.chat-message.operator .name {
    color: #4fb0e5;
}

.chat-message.caller .name {
    color: #444;
}

.live-chat .chat-ui {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e7e8e9;
    padding-top: 15px;
    background-color: #fff;
}

.chat-input {
    border: none;
    width: 80%;
    font-size: 1.4rem !important;
    outline: none;
}

.chat-button {
    width: 16px;
    height: 24px;
    border: none;
    background-color: inherit;
    cursor: pointer;
    font-weight: bolder;
    font-size: 2.4rem;
    padding: 0 8px;
    margin: 0 3px;
}

.chat-button[type='submit'] {
    color: green;
    line-height: 0;
}

.chat-button[type='button'] {
    color: red;
}

.chat-button:disabled {
    color: grey;
}

.chat-ui .chatBoxControls {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #f0f0f1;
    padding: 6px 8px;
}

.message-panel {
    padding: 12px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1);
    width: 250px;
    position: fixed;
    bottom: 10px;
}

.message-panel .header-bar {
    cursor: pointer;
    width: 100%;
    text-transform: uppercase;
    font-size: 1.25rem;
    background-color: #f2f2f2;
}

.message-panel-container {
    display: table-cell;
    width: 50%;
}

.cells {
    display: table;
}

.cell {
    display: table-cell;
}

.header-bar .cell {
    height: 39px;
    vertical-align: middle;
    padding: 0 15px;
    position: relative;
    font-size: 1.4rem;
    letter-spacing: 0.28px;
}

.header-bar .right.cell {
    font-size: 0;
}

.header-bar .subtitle:before {
    content: ' - ';
}

.badge {
    display: inline-block;
    color: #fff;
    text-align: center;
    width: 18px;
    height: 18px;
    line-height: 18px;
    background-color: #54a72e;
    border-radius: 30px;
    font-size: 1rem;
}

.header-bar .badge {
    margin-top: -5px;
    margin-left: 10px;
}

[class*='grid-item-tab-menu'] .badge {
    position: absolute;
    top: 6px;
    right: 2rem;
}

.cell.right {
    text-align: right;
}

.message-panel .content {
    padding: 0 0;
    height: 0;
    overflow: hidden;
    transition: height 0.25s ease;
}

.message-panel .scrollbar-v {
    padding: 10px 15px;
}

@media only screen and (max-width: 1024px) and (orientation: portrait),
(max-width: 480px) {
    .chat-message {
        max-width: 80vw;
    }
}

@media only screen and (max-device-width: 900px) and (orientation: portrait) {
    .live-chat {
        border-top: 1px solid #e7e8e9;
    }
}

@media only screen and (max-width: 480px) and (orientation: portrait) {
    [class*='grid-item-tab-menu'] .badge {
        right: 2px;
    }
}

/* CSS specific to iOS devices */
@supports (-webkit-touch-callout: none) {

    @media only screen and (min-width: 1366px) and (max-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
        .chat-button[type='button'] {
            line-height: 0;
        }

        .chat-button {
            padding: 0;
        }
    }

    @media only screen and (max-device-width: 1024px) and (orientation: portrait),
    (min-width: 900px) and (max-width: 1200px) and (orientation: landscape) {
        .chat-button {
            width: 24px;
            margin: 0;
            line-height: unset !important;
        }

        .message-panel {
            width: 260px;
        }
    }

    @media only screen and (max-device-width: 900px) and (orientation: portrait) {
        .chat-input {
            font-size: 16px !important;
        }
    }
}