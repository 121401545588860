.modal {
    font-family: 'Lato', 'Roboto', Arial, sans-serif;
}

/* The Modal (background) */
.modal {
    // display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 10001; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    display: flex;
    align-items: center;
    background-color: #FFA500;
    margin: 0 auto;
    padding: 13px;
    border: 1px solid #888;
    width: 30%;
    color: #fefefe;
    font-size: 14px;
    border-radius: 18px;
    justify-content: center;
}

.modal-content .material-icons {
    margin-right: 5px;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 30px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}
/* The Close Button */
.close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

@media only screen and (max-width:1112px) {
    .modal-content {
        margin: 5px auto;
        padding: 18px;
        width: 50%;
    }
}

@media only screen and (max-width:512px) {
    .modal-content {
        width: 70%;
    }
}
