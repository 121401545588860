.sale-history {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top:5px;
    column-gap: 1rem;
}
.sale-history .sale-history-entries {
    font-size: 1.4rem;
    height: 36px;
    vertical-align: middle;
    text-transform: uppercase;
    color: #6a6a6a;
    opacity: 0.8;
    padding-right: 20px;
}