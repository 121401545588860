$grade-1-color: #87a92d;
$grade-2-color: #cfd020;
$grade-3-color: #ffc831;
$grade-4-color: #f48404;
$grade-5-color: #d83308;

.subtitle:before {
    content: " - ";
}

.grade {
    width: 38px;
    height: 38px;
    text-align: center;
    line-height: 36px;
    color: #fff;
    background-color: #707070;
    border-radius: 40px;
    display: inline-block;
    font-size: 2.3rem;
    font-weight: bold;
    text-transform: uppercase;
}

.grade-1 {
    background-color: $grade-1-color;
}

.grade-2 {
    background-color: $grade-2-color;
}

.grade-3 {
    background-color: $grade-3-color;
}

.grade-4 {
    background-color: $grade-4-color;
}

.grade-5 {
    background-color: $grade-5-color;
}

.compact {
    width: 24px;
    height: 24px;
    line-height: 22px;
    font-size: 1.5rem;
    color: #000;
}

li > .compact {
    width: 18px;
    height: 18px;
    line-height: 18px;
    font-size: 1.2rem;
    color: #000;
}
 
@media only screen and (max-width: 1200px) {
    .compact {
        font-size: 1.1rem;
        width: 18px;
        height: 18px;
        line-height: 18px;
        margin-left: 6px;
    }
}
