.layout {
    font-size: 16px;
    margin: 0;
    padding: 0;
    max-width: 100vw;
    max-height: 100vh;
    height: 100vh;
    overflow: hidden;
}

.header {
    height: 60px;
    margin-bottom: 22px;
    display: flex;
    justify-content: space-between;
}

.content {
    width: 100vw;
    height: calc(100vh - 52px);
    overflow-y: auto;
}

.sales-list {
    flex-grow: 1;
    width: 24%;
    max-width: 24%;
    transition: ease-in-out 1s;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}

.sales-list.minimize {
    flex-grow: 0;
    width: 8%;
}

.sale-list-item {
    color: whitesmoke;
    width: 90%;
    height: 30%;
    padding: 0 0.8rem;
    margin: 1rem 0;
}

@media screen and (max-width: 1400px) {
    .header {
        height: 45px;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 1024px) and (orientation:portrait) {
    .layout {
        position: fixed;
        max-height: 100%;
        min-height: 100%;
        max-width: 100vw;
        width: 100%;
        box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.04);
    }
}

@media only screen and (max-height: 600px) and (max-width: 1210px) and (orientation: landscape) {
    .content {
        height: calc(100vh - 160px);
    }
}

@media screen and (max-width: 1000px) {


    .header {
        flex-direction: row-reverse;
    }

    .content {
        height: calc(100% - 45px);
    }

    .sales-list.minimize {
        flex-grow: 0;
        width: 16%;
    }
}

@media screen and (max-width: 900px) {
    .header {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 600px) {
    .sales-list {
        flex-grow: 2;
        min-width: 0;
    }

    .sales-list.minimize {
        width: 0;
    }
}
