.cell {
    display: table-cell;
    vertical-align: middle;
}

.text-right {
    text-align: right;
}

.current-lot-accordions-container {
    max-height: 450px;
}


/* Mobile Footer
---------------------------------------*/

.mobile-footer {
    position: sticky;
    bottom: 0;
    width: 100%;
    flex-wrap: wrap;
    align-content: center;
    z-index: 9000;
}


/*==============================New Layout code==============================*/

.grid-main-sale {
    display: grid;
    grid-template-columns: 29% minmax(40%, auto) 19vw;
    grid-template-rows: repeat(2, auto) repeat(2, 8%) 10% 45% 10%;
    column-gap: 2%;
    row-gap: 1px;
    grid-auto-flow: column;
    flex-grow: 2;
    box-shadow: 0 2px 6px 2px rgb(0 0 0 / 4%);
    padding: 0 14px 14px 14px;
    margin-left: 25px;
}

.grid-item-sale-header {
    grid-area: 1 / 1 / 2 / 3;
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid #a4acb0;
    justify-content: space-between;
}

.grid-item-lot-header {
    grid-area: 2 / 1 / 3 / 3;
    margin: 5px 0;
}


.grid-item-tab-menu {
    grid-area: 3 / 1 / 3 / 3;
    padding-right: 12px;
    position: relative;
}

.grid-item-tab-content {
    grid-area: 4 / 1 / 7 / 3;
    padding-right: 12px;
    margin-top: 1rem;
}

.grid-item-right-container {
    grid-area: 1 / 3 / 8 / 4;
    position: relative;
}

.grid-item-section-current-lot-details {
    display: grid;
    grid-template-columns: 53% 45%;
    grid-gap: 3%;
}

.current-lot-accordions-container {
    grid-area: 1 / 1 / -1 / 2;
}

.grid-item-footer {
    grid-area: 7 / 1 / 8 / 3;
    z-index: 501;
}

.footer-section-wrapper {
    display: table; 
    width: 111%;
}

.footer-section-wrapper > div {
    flex-shrink: 0;
}

@media screen and (min-width:1680px) and (max-width:2500px) {
    .grid-main-sale {
        grid-template-rows: repeat(2, auto) 8% 12% 10% 45% 10%;
    }
}

@media only screen and (min-width: 1025px) {
    .grid-item-sale-header, .grid-item-lot-header {
        padding-top: 14px;
    }

    .main-sale-right-border {
        width: 4px;
        position: absolute;
        box-shadow: 2px 0px 2px 0px rgb(0 0 0 / 4%);
        height: 101%;
        top: 0;
        bottom: 0;
        left: -16px;
    }
}

@media only screen and (min-width: 1900px) {
    .grid-main-sale {
        grid-template-columns: 29% auto 22vw;
    }
}

/*--------Ipad Mini (landscape) And Galaxy Tabs----*/
@media only screen and (min-width: 1024px) and (max-width: 1280px) and (min-height: 580px) and (max-height: 780px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {    
    .grid-main-sale {
        grid-template-columns: 29% auto 28vw;
    }
}

@media screen and (max-width: 1400px) {
    .grid-main-sale {
        margin-left: 12px;
    }
}

@media screen and (max-width: 1024px) and (max-aspect-ratio: 13/9) {
    .grid-main-sale {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, auto);
        padding: 0;
        box-shadow: none;
        margin-left: 0;
    }

    .grid-item-sale-header {
        grid-area: 1 /1 / 2 / 2;
        border-bottom: 1px solid #a4acb0;
        background-color: #f9fafc;
        display: table;
        padding-top: 1rem;
        width: 100%;
    }

    .grid-item-lot-header {
        grid-area: 2 /1 / 3 / 2;
        margin: 0;
    }

    .grid-item-tab-content .vehicle-images {
        display: none;
    }

    .grid-item-section-current-lot-details {
        grid-template-columns: auto;
    }

    .grid-item-tab-content {
        grid-area: 3 / 1 / 4 / 2;
    }

    .grid-item-tab-content .pricing-bidding-container {
        overflow-y: auto;
    }

    .current-lot-accordions-container {
        max-height: 60vh;
    }

    .div-mobile-vendor-logo {
        padding: 1.5% 3%;
        background-color: #f2f2f2;
        font-size: 1.4rem;
        margin-bottom: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #a4acb0;

        img {
            max-width: 50px;
        }
    }
}


@media screen and (max-width: 760px) {
    .current-lot-accordions-container {
        max-height: 52vh;
    }

    .grid-item-sale-header {
        position: relative;
    }

    .grid-item-sale-header .cell.text-right [class*='notify-badges'] {
        position: unset;
    }
}

@media screen and (max-width: 480px) and (max-height: 845px) {
    .current-lot-accordions-container {
        max-height: 44vh;
    }
}

@media screen and (max-width: 480px) and (max-height: 750px) {
    .current-lot-accordions-container {
        max-height: 39vh;
    }
}

@media screen and (max-width: 480px) and (max-height: 675px) {
    .current-lot-accordions-container {
        max-height: 32vh;
    }
}
