.toggleSwitch 
{
  position: relative;
  width: 42px;
  cursor: pointer;
	display: inline-block;
}

.toggleSwitch .track 
{
  width: 100%;
  height: 12px;
  background-color: #516787;
  border-radius: 20px;
  position: relative;
  z-index: 100;
}

.toggleSwitch .handle 
{
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 20px;
  background-color: #bdc1d0;
  position: absolute;
  top: -4px;
  left: 0;
  z-index: 200;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
}

.toggleSwitch .handle 
{
  transition: left 0.1s ease, background-color 0.1s ease;
}

.toggleSwitch .track 
{
  transition: background-color 0.1s ease;
}

.toggleSwitch.active .handle 
{
  left: 24px;
  background-color: #54a72e;
}

.toggleSwitch.active .track 
{
  background-color: #225282;
}