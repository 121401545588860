.buyer-vendor-view {
    position: relative;
}

.buyer-vendor-view-body {
    display: flex;
    width: 100%;
    height: auto;
    max-height: 2000px;
    position: relative;
    z-index: 1000;
}

@media only screen and (min-width: 1024px) and (orientation:landscape) {
    .buyer-vendor-view-body {
        /* height: calc(100% - 82px); */
        height: 100%;
        min-height: 854px;
    }
}


@media only screen and (max-width: 1024px) and (orientation: portrait),
                       (max-width: 1024px) and (max-aspect-ratio: 13/9) {
    .buyer-vendor-view-body {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
        height: 100%;
        max-height: 100%;
        overflow: hidden;
        z-index: auto;
    }
}
