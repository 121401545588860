$colorBlue: #0094ff;

.d-none {
    display: none !important;
    background-color: $colorBlue;
}

.d-flex {
    display: flex;
}

.d-flex-50 {
    flex: 50%;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}
