.pricing-information {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .pricing-information-title {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: nowrap;
        align-items: center;
        color: #087bbc;
        line-height: 20px;
        text-transform: uppercase;
        font-size: 1.25rem;
        font-weight: 600;
        cursor: default;

        .symbol {
            font-size: 25px;
            padding-right: 5px;
        }
    }

    .price-container {
        display: flex;
        flex-direction: column;
        margin: 5px;
        justify-content: space-between;

        .price-label {
            display: flex;
            flex-direction: column;
            text-transform: uppercase;
            font-size: 12px;
            color: #888888;
            flex-wrap: wrap;

            span {
                display: flex;
            }
        }

        .price-value {
            display: flex;
            color: black;
            font-size: 1.4rem;
        }
    }
}

.tooltip {
    position: relative;
    display: flex;
    color: #087bbc;
    align-items: center;

    .tooltip-text {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        position: absolute;
        width: 120px;
        bottom: 100%;
        left: 50%;
        margin-left: -60px;
        z-index: 999;
        transition: 0.3s;
        opacity: 0;
    }

    &:hover .tooltip-text {
        visibility: visible;
        opacity: 1;
    }
}

.pricing-information-main {
    display: flex;
    flex-direction: row;
}


@media only screen and (max-device-width:1025px) and (orientation:portrait) {
    .pricing-information .price-container .price-label span:first-child {
        margin-right: 6%;
    }

    .pricing-information .price-container .price-label {
        flex-direction: row;
        font-size: 1.4rem;
        flex-wrap: nowrap;
        margin-right: 35%;
    }

    .pricing-information .price-container .price-value {
        word-break: break-all;
        font-weight: 600;
    }

    .pricing-information .price-container {
        width: 48%;
        margin: 4px;
        flex-direction: row;
    }

    .pricing-information-main {
        background-color: #f9fafc;
        padding: 2% 4%;
        flex-direction: column;
    }

    .pricing-information-title {
        white-space: nowrap;
        color: #087bbc;
        font-size: 1.3rem;
        font-weight: 600;
        text-transform: uppercase;
        opacity: .8;
        font-family: "Lato", 'Roboto', Arial,sans-serif;
    }

    .pricing-information {
        padding-top: 1%;
    }

    .cap-retail {
        order: 1;
    }

    .cap-average {
        order: 2;
    }

    .cap-clean {
        order: 3;
    }

    .glass-trade {
        order: 4;
    }

    .inspection-cost {
        order: 5;
    }

    .siv {
        order: 6;
    }
}

@media only screen and (max-device-width:767px) {
    .pricing-information .price-container .price-label {
        margin-right: 15%;
    }
}

@media only screen and (max-device-width:374px), only screen and (max-device-width:413px) and (max-device-height:700px) {
    .pricing-information-title {
        font-size: 1.5rem;
    }

    .pricing-information {
        padding-top: 0;
    }
}

@media only screen and (max-width: 480px) {

    .pricing-information .price-container {
        flex-basis: 100%;
        justify-content: space-between;
    }
}