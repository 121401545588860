@import 'colors';

.options-menu 
{
    position: relative;
    padding-right: 20px;
    padding-left: 25px;
    margin-left: 1.5rem;
}

.options-menu .trigger .label 
{
    font-size: 1.4rem;
    font-weight: 600;
    transition: color 0.3s ease;
}

.options-menu .on 
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.options-menu .a,
.options-menu .d 
{
    display: block;
    position: absolute;
    top: 50%;
}

.options-menu .d 
{
    left: 0;
    margin-top: -12px;
}

.options-menu .a 
{
    width: 12px;
    height: 8px;
    background-image: url(/img/icon-menu-arrow.png);
    right: 0;
    margin-top: -4px;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.options-menu .a .on 
{
    background-image: url(/img/icon-menu-arrow-on.png);
}

.options-menu .d .on 
{
    background-image: url(/img/icon-menu-decal-on.png);
}

.options-menu.active .trigger .label 
{
    color: #087bbc;
}

.options-menu.active .a 
{
    transform: rotate(180deg);
}

.options-menu.active .on 
{
    opacity: 1;
}

.options-menu .options 
{
    display: none;
    background-color: #fff;
    position: absolute;
    top: 30px;
    right: 1rem;
    min-width: 225px;
    max-width: 500px;
    padding: 10px;
    padding-bottom: 15px;
    text-align: left;
    opacity: 0;
    transition: opacity 0.5s ease;
    box-shadow: 0px 2px 6px 2px rgb(0 0 0 / 10%);
}

.options-menu .options [class*="title"],
.options-menu .options [class*="option"] 
{
    font-weight: 600;
    padding: 10px;
}

.options-menu .options [class*="title"] 
{
    font-size: 1.5rem;
    color: #087bbc;
}

.options-menu .option 
{
    font-size: 1.4rem;
    position: relative;
}

.options-menu .option > span[class*="toggle-switch"]
{
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -6px;
}

.options-menu.active .options 
{
    opacity: 1;
    display: block;
}

.blue-icon {
    color: $azure-radiance;
}
