.closer {
    width: 16px;
    height: 25px;
    top: 0;
    right: 0;
    display: block;
    z-index: 1000;
    position: absolute;
    background-repeat: no-repeat;
    background-position: 100% 50%;
    background-image: url(img/icon-close.png); 
}

.closer:hover {
    background-image: url(img/icon-close-on.png);
}