.fate-bold {
    font-weight: bold;
}

.sold-prov {
    color: orange;
}

.sold {
    color: green;
}

.unsold {
    color: red;
}