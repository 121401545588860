.inspection-link {
    display: inline-flex;
    color: rgba(8, 123, 188, 1);
    font-size: 1.25rem;
    font-weight: 600;
    flex-wrap: wrap;
    justify-content: center;

    &:hover {
        color: rgba(11, 155, 238, 1);
    }

    img {
        margin: auto;
        filter: brightness(100%);

        :hover > & {
            filter: brightness(120%);
        }
    }

    span {
        text-transform: uppercase;
        padding: 4px;
    }
}

.large {
    max-width: 100%;
}

.large span {
    padding: 4px 8px;
}

.medium {
    font-size: 1.2rem;
}

.medium span {
    max-width: 75px;
    overflow: hidden;
    max-height: 100%;
    text-align: left;
}

.small {
    font-size: 1.2rem;
    line-height: 100%;
    width: 70%;
}

.xs {
    width: 50%;
}

.xs span {
    display: none;
}
