@import 'colors';

.container {
    overflow-y: auto;
    width: 350px;
}

.container > .announcement-row   {
    background-color: rgb(0 0 0 / 5%);
    border-radius: 4px;
    padding: 1.5rem;
    margin-bottom: 8px;
    font-weight: normal;
}

.container .announcement-type {
    text-transform: uppercase;
    background: $mystic;
    padding: 4px 8px;
    width: fit-content;
    border-radius: 4px;
    color: $congress-blue;
    font-weight: 700;
    margin-bottom: 6px;
}

.container .announcement-description {
    white-space: break-spaces;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 4px 0 8px 0;
}

.container .btn-view-now {
    background: $lochmara;
    color: $white;
    padding: 6px 1rem;
    border-radius: 2rem;
    display: inline-block;
}

.container .btn-view-now:hover {
    cursor: pointer;
}

.container .btn-show-more {
    color: $lochmara;
    text-align: center;
    margin: 1rem auto 0 auto;
    display: block;
    padding: 8px;
    border: 1px solid transparent;
}

.container .btn-show-more:hover {
    border-radius: 4px;
    color: $congress-blue;
    border-color: $congress-blue;
    cursor: pointer;
}