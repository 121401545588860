.vendor-section {
    .vendor-online {
        display: flex;
        flex-direction: column; 
        gap: 5px;
        width: 100%;
        flex-shrink: 1;
        flex-grow: 1;
    }

    .your-auctioneers {
        font-size: 1.4rem;
    }
}

.vendor-logo {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    align-content: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 1;
    flex-grow: 0;

    img {
        display: flex;
        max-height: 45px;
        flex-shrink: 1;
        flex-grow: 1;
        align-self: center;

        @media screen and (max-width: 1000px) {
            max-width: 55px;
        }
    }
}

.lot-header-lights.cells {
    display: flex;
    width: auto;
    margin-bottom: 13px;
    justify-content: space-between;
}

.lot-header-lights .cell {
    display: table-cell;
    padding-right: 16px;
    vertical-align: middle;
}
/* Registration Plate */

.vehicle-reg {
    width: 83px;
    line-height: 25px;
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #f2f2f2;
    color: #444444;
    border: 1px solid #a4acb0;
    text-align: center;
    font-family: 'Roboto', Arial, sans-serif;
}

.sale-date:before, .sale-time:before {
    content: ' \00BB  ';
}

.auction {
    width: 50%;
    vertical-align: top;
    display: table-cell;
    flex-direction: column;
}

.auction [class*="heading-component"] {
    font-weight: 800;
}

.auction .subheadline {
    font-size: 1.4rem;
    letter-spacing: 0.28px;
}

.online-status {
    width: 138px;
    height: 36px;
    line-height: 34px;
    color: #fff;
    font-size: 1.4rem;
    background-color: #dfe0e2;
    text-align: center;
    border-radius: 20px;
    padding-left: 10px;
}

.online-status {
    background-image: url(/img/icon-online.png);
    background-position: 10px calc(50% - 1px);
    background-repeat: no-repeat;
}

.online-status.active {
    background-color: #4fb0e5;
}

[class*="grid-item-sale-header"] .close-button {
    position: relative;
    width: 17px;
}

.lot-header-title {
    display: flex;
    flex-direction: row;
}
 
.lot-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    align-items: center;
}

.lot-header [class*="heading-component"] {
    font-weight: 800;
    flex-basis: 30%;
}

.lot-header .lot-header-title {
    flex-basis: 30%;
    align-items: center;
}

.lot-header .sale-item {
    font-size: 1.45rem;
    flex-basis: 75%;
}

.grade-icon {
    width: 75px;
    height: 36px;
    font-size: 1.4rem;
    background-color: #1a3761;
    border-radius: 100px;
    display: inline-table;
}

.grade-icon-1, .grade-icon-2, .grade-icon-3, .grade-icon-4, .grade-icon-5, .grade-icon-U {
    background-size: 40%;
    border-width: 0;
    height: 100%;
    border-radius: 100px;
    border: 3px solid #1a3761;
    background-repeat: no-repeat;
    background-position: 0 50%;
    display: table-cell;
    vertical-align: middle;
    color: white;
    text-align: right;
}

.grade-icon-1 {
    background-image: url(/img/grade/grade-1.svg);
}

.grade-icon-2 {
    background-image: url(/img/grade/grade-2.svg);
}

.grade-icon-3 {
    background-image: url(/img/grade/grade-3.svg);
}

.grade-icon-4 {
    background-image: url(/img/grade/grade-4.svg);
}

.grade-icon-5 {
    background-image: url(/img/grade/grade-5.svg);
}

.grade-icon-U {
    background-image: url(/img/grade/grade-u.svg);
}

.mot-icon {
    width: 156px;
    height: 36px;
    font-size: 1.4rem;
    background-color: #1a3761;
    border-radius: 100px;
    display: inline-table;
    background-size: 20%;
    border-width: 0;
    border-radius: 100px;
    border: 3px solid #1a3761;
    background-repeat: no-repeat;
    background-position: 0 50%;
    display: table-cell;
    vertical-align: middle;
    color: white;
    background-image: url(/img/mot/mot.svg);
}

.mot-icon-positive {
    left: 25%;
    position: relative;
}

@media only screen and (max-width: 1770px) {
    .lot-header-lights .cell:last-child {
        padding-right: 0;
    }

    .auction {
        padding-right: 10px;
    }

    .auction [class*="heading-component"],
    .lot-header [class*="heading-component"] {
        margin-bottom: 5px;
    }

    .auction [class*="heading-component"] {
        font-size: 1.5rem;
        line-height: 19px;
    }
}

@media only screen and (max-width:1400px) {
    .grade-icon {
        width: 55px;
        height: 22px;
        font-size: 1.1rem;
    }

    .grade-icon-1, .grade-icon-2, .grade-icon-3, .grade-icon-4, .grade-icon-5, .grade-icon-U {
        background-size: 35%;
    }

    .mot-icon {
        width: 115px;
        height: 22px;
        font-size: 1.1rem;
        background-size: 15%;
        font-size: 1.1rem;
    }

    .mot-icon-positive {
        left: 21%;
    }

    .online-status {
        height: 22px;
        line-height: 22px;
        width: 116px;
        font-size: 1.1rem;
    }
}

@media only screen and (min-width:1281px) and (max-width:2500px) {
    .lot-header [class*="heading-component"] {
        flex-basis: 40%;
    }
}
@media only screen and (max-device-width:1025px) and (orientation:portrait), 
                       (max-width: 1024px) and (max-aspect-ratio: 13/9) {

    .lot-header-lights.cells {
        width: auto;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2%;
    }

    .lot-header-lights .cell {
        text-align: center;
        padding-right: 9px;
    }

    .vendor-logo {
        width: 22px;
        height: 22px;
    }

    .vendor-logo.offline {
        background-image: url(/img/vendor-offline.svg);
    }

    .vendor-logo.online {
        background-image: url(/img/vendor-online.svg);
    }

    .vendor-section, .auction .subheadline {
        display: none;
    }

    .auction {
        border-right: hidden;
    }

    .auction [class*="heading-component"] {
        line-height: 145%;
        margin-bottom: 0;
    }

    .sale-date:before, .sale-time:before {
        content: ' - ';
    }
}

@media only screen and (max-width: 1200px) {
    .vehicle-reg {
        height: 21px;
        line-height: 21px;
        width: 78px;
    }
}


@media only screen and (max-width: 535px) {
    .lot-header-lights.cells {
        width: 100%;
    }

    .vehicle-reg {
        height: 21px;
        line-height: 21px;
        width: 78px;
    }
}

@media only screen and (max-width: 374px),only screen and (max-width: 413px) and (max-height:700px) {

    .lot-header-lights.cells {
        margin-bottom: 1%;
        width: 100%;
    }
}


@media only screen and (min-width:1900px) { 

    .lot-header .sale-item {
        flex-basis: 75%;
    }
     

}

@media only screen and (max-width:1024px) and (orientation:portrait), (max-width: 1024px) and (max-aspect-ratio: 13/9) {
    .lot-header [class*="heading-component"]:after {
        content: ":";
    }

    .lot-header [class*="heading-component"] {
        flex-basis: 9%;
        font-weight: 800;
        color: #004489;
        font-size: 1.8rem;
    }

    .lot-header .sale-item {
        font-size: 1.8rem;
        line-height: 122%;
        flex-basis: 76%;
        text-align: left;
    }

    .lot-header .lot-header-title {
        flex-basis: 24%;
        flex-direction: column;
    }

    [class*="grid-item-sale-header"] .close-button.close-button {
        display: none;
    }

    .lot-header {
        margin-bottom: 2%;
        text-align: center;
    }

    .auction {
        width: auto;
        vertical-align: middle;
    }

    .lot-header .lot-header-title .cell {
        margin-left: 25%;
    }

    .grade-icon {
        width: 25px;
        background-color: transparent;
    }

    .grade-icon-1, .grade-icon-2, .grade-icon-3, .grade-icon-4, .grade-icon-5, .grade-icon-U {
        background-size: 85%;
        border-width: 0;
    }
}

@media only screen and (max-width: 514px) {

    .lot-header [class*="heading-component"] {
        flex-basis: 13%;
    }

    .lot-header .lot-header-title .cell {
        margin-left: 0;
    }
}

@media only screen and (max-width: 514px) {
    .mot-icon {
        width: 85px;
        background-size: 21%;
    }

    .lot-header-lights .cell {
        padding-right: 10px;
    }

    .mot-icon-positive {
        left: 10%;
    }
}

@media only screen and (max-width: 413px) {
    .lot-header [class*="heading-component"] {
        flex-basis: 20%;
        font-size: 1.5rem;
    }

    .lot-header .sale-item {
        font-size: 1.5rem;
    }

    .lot-header {
        margin-bottom: 1%;
        column-gap: 1rem;
    }
}

@media only screen and (max-width: 390px) {
    .mot-icon {
        width: 85px;
        background-size: 21%;
    }

    .lot-header-lights .cell {
        padding-right: 6px;
    }
}

@media only screen and (max-width: 360px) { 
    .lot-header-lights .cell {
        padding-right: 2px;
    }
}