/* Announcements/Remarks */
.messages {
    padding: 0 8px;
    margin-bottom: 12px;
}

.message {
    line-height: 110%;
    margin-bottom: 8px;
    background-repeat: no-repeat;
    background-position: 0;
    font-size: 1.4rem;
    letter-spacing: 0.28px;
}

.message .label {
    text-transform: uppercase;
}

.announce.message .label {
    color: #4ea83b;
}

.announce.message .label::before {
    content: url(/img/icon-announcements.png);
}

.remark.message .label {
    color: #ec9d00;
}

.remark.message .label::before {
    content: url(/img/icon-remarks.png);
}

:is(.announce.message, .remark.message) .label::before {
    min-height: 16px;
    vertical-align: middle;
    padding-right: 8px;
}

@media only screen and (max-width:1024px) and (orientation:portrait) {

    .messages {
        width: 100%;
        padding-left: 3%;
        padding-right: 3%;
        margin-bottom: 0;
    }

    .message {
        background-repeat: no-repeat;
        background-position: 0 2px;
        line-height: 1.6rem;
        margin-bottom: 0;
    }

    .message .label {
        text-transform: capitalize;
    }
}
