.page-nav {
    position: relative;
    margin-bottom: 32px;
}

.page-nav .line {
    background-color: #127bbc;
    height: 8px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 20%;
    transition: width 0.2s ease;
    display: none;
}

.tabs {
    width: 100%;
    display: table;
    table-layout: fixed;
    position: relative;
}

.tab {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    height: 60px;
    font-size: 1.5rem;
    font-weight: 500;
    border-width: 0;
    border-bottom-width: 2px;
    border-style: solid;
    border-color: #004489;
    transition: color 0.5s ease, border-color 0.5s ease;
    cursor: pointer;
}

.tab:hover {
    color: #888;
}

.tab .l {
    width: 100%;
    height: 6px;
    background-color: #127bbc;
    position: absolute;
    bottom: 0;
    left: 0;
    display: none;
}

.active.tab {
    color: #127bbc;
    border-color: #127bbc;
}

.tab.focused .l {
    display: block;
}

.mobile-nav-button {
    text-align: center;
    background-color: #f9fafc;
    display: block;
    width: 100%;
    padding: 8px;
}

.mobile-nav-button .label {
    display: block;
    font-size: 1.4rem;
    line-height: 120%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.mobile-nav-button .icons {
    display: block;
    position: relative;
    width: 100%;
    padding-bottom: 8px;
    min-height: 27px;
    margin-bottom: 2px;
}

.mobile-nav-button .icon {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: auto 80%;
    transition: opacity 0.5s ease;
}

.mobile-nav-button .icon-on {
    opacity: 0;
}

.mobile-nav-button .icon-off,
.mobile-nav-button.active .icon-on {
    opacity: 1;
}

.mobile-nav-button.ui-current-lot .icon {
    background-size: auto 50%;
}

.mobile-nav-button.active {
    background-color: #0781c5;
    color: #fff;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.mobile-nav-button.active .icon-off {
    opacity: 0;
}

.mobile-nav-button.ui-all-lots .icon-off {
    background-image: url(/img/mobile/ui-all-lots.svg);
}

.mobile-nav-button.ui-current-lot .icon-off {
    background-image: url(/img/mobile/ui-current-lot.svg);
}

.mobile-nav-button.ui-inspection-report .icon-off {
    background-image: url(/img/mobile/ui-icon-report.svg);
}

.mobile-nav-button.ui-live-view .icon-off {
    background-image: url(/img/mobile/ui-live-view.svg);
}

.mobile-nav-button.ui-messages .icon-off {
    background-image: url(/img/mobile/ui-messages.svg);
}

.mobile-nav-button.ui-proxy-bids .icon-off {
    background-image: url(/img/mobile/ui-proxy-bids.svg);
}

.mobile-nav-button.ui-winning-lots .icon-off {
    /*background-image: url( ./img/mobile/ui-winning-lots.svg );*/
}

.mobile-nav-button.ui-all-lots .icon-on {
    background-image: url(/img/mobile/ui-all-lots-on.svg);
}

.mobile-nav-button.ui-current-lot .icon-on {
    background-image: url(/img/mobile/ui-current-lot-on.svg);
}

.mobile-nav-button.ui-inspection-report .icon-on {
    background-image: url(/img/mobile/ui-icon-report-on.svg);
}

.mobile-nav-button.ui-live-view .icon-on {
    background-image: url(/img/mobile/ui-live-view-on.svg);
}

.mobile-nav-button.ui-messages .icon-on {
    background-image: url(/img/mobile/ui-messages-on.svg);
}

.mobile-nav-button.ui-proxy-bids .icon-on {
    background-image: url(/img/mobile/ui-proxy-bids-on.svg);
}

.mobile-nav-button.ui-winning-lots .icon-on {
    /*background-image: url( ./img/mobile/ui-winning-lots-on.svg );*/
}

@media only screen and (max-width: 900px) {
    .tab {
        border-bottom: none;
        border-bottom-width: 0;
    }

    .page-nav {
        margin-bottom: 0;
    }
}

@media only screen and (max-width:768px) {
    .page-nav{
        overflow-x: auto;
    }
    .page-nav nav{
        width: -webkit-fill-available;
    }
}

@media only screen and (max-width: 535px) {
    .mobile-nav-button .label {
        font-size: 1rem;
        letter-spacing: 0.2px;
    }

    .mobile-nav-button .icon {
        background-size: auto 95%;
    }

    .mobile-nav-button.ui-current-lot .icon {
        background-size: auto 66%;
    }

    .mobile-nav-button {
        min-width: 65px;
    }
}
