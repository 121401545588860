.heading-component {
    position: relative;
    margin: 0;
    padding: 0;
}

.dark-blue {
    color: #004489;
}

.blue {
    color: #087bbc;
}

.small {
    font-size: 1.4rem;
}

.medium {
    font-size: 2.3rem;
}

.large {
    font-size: 3rem;
}

.extra-large {
    font-size: 5rem;
}

.start {
    text-align: start;
}

.center {
    text-align: center;
}

.end {
    text-align: end;
}