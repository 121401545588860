.div-all-lots-mobile-container {
    overflow: hidden auto;
}

.lot-info-container {
    display: flex;
    border-top: 1px solid #a4acb0;
    padding-top: 8px;
}

.section-image-and-number-plate {
    width: 40%;
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    height: fit-content;
}

.section-image-and-number-plate [class*='heading-component'] {
    margin-right: 4px;
}

.section-image-and-number-plate [class*='vehicle-reg'] {
    flex-grow: 1;
}


.lot-image {
    max-width: 100%;
    margin: 1rem 0;
    flex: 100%;
}

.section-lot-details {
    max-width: 60%;
    margin-left: 1rem;
}

.section-lot-lights {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.section-lot-lights [class*='inspection-link'] {
    max-width: 30px;
}

.div-current-Lot {
    background-color: rgb(223 238 255 / 65%);
}

.fated-lot {
    background-color: rgba(0, 0, 0, 10%);
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .section-image-and-number-plate {
        width: 30%;
    }

    .lot-image {
        max-height: 200px;
    }
}