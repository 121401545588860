
.data-table .columns {
    width: 100%;
    display: table;
    border-collapse: collapse;
}

.data-table .column {
    display: table-cell;
}

.data-table .column > .inner {
    display: table;
    width: 100%;
}


.data-table .row {
    display: table-row;
    border-bottom: 1px solid #f2f2f2;
    width: 100%;
    position: relative;
}

.data-table .row .key {
    text-transform: uppercase;
    color: #6a6a6a;
    opacity: .8;
    padding-right: 20px;
    padding-top: 2px;
    font-size: 1.4rem;
}

.data-table .row .value {
    font-weight: 600;
    word-break: break-word;
}

.data-table .row .key, .data-table .row .value {
    display: table-cell;
    font-size: 1.4rem;
    height: 36px;
    vertical-align: middle;
    position: relative;
}

.data-table .key .title {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -7px;
    white-space: nowrap;
    color: #087bbc;
    font-size: 1.3rem;
    line-height: 100%;
    font-weight: 600;
}

.data-table .row:last-child {
    border-bottom: none;
} 

/* CSS for multi-column*/
.data-table.multi-column {
    width: 100%;
    display: table;
    padding: 10px 0;
}

.data-table.multi-column .key {
    padding-right: 18px;
}

.data-table.multi-column .value {
    padding-right: 0;
}

.data-table.multi-column .key,
.data-table.multi-column .value {
    padding-left: 0;
    height: 25px;
    font-size: 1.2rem;
} 
@media only screen and (max-width: 374px), (max-height: 700px) {
    .data-table .key .title {
        font-size: 1.2rem;
        margin-top: -5px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .data-table.expander-vehicle-details .row {
        display: flex;
    }

    .data-table.expander-vehicle-details .column {
        display: block;
    }

    .data-table.expander-vehicle-details .key,
    .data-table.expander-vehicle-details .value {
        padding: 10px 0;
        width: 50%;
        height: auto;
    }

    .vehicle-details-inner .row:last-child {
        border-bottom: 1px solid #f2f2f2 !important;
    }
}

@media only screen and (max-width: 1200px) {


    .data-table .key {
        padding-bottom: 2px;
        padding-top: 5px;
    }

    .data-table .value {
        padding-bottom: 5px;
    }

    .data-table.value-only .value {
        padding: 8px 0;
        font-weight: normal;
    }

    .data-table.multi-column .key,
    .data-table.multi-column .value {
        font-size: 1.1rem;
        vertical-align: top;
        padding-right: 5px;
    }

    .data-table.multi-column .key:last-child,
    .data-table.multi-column .value:last-child {
        padding-right: 0;
    }

    .data-table.multi-column {
        table-layout: fixed;
    }
}
@media only screen and (max-width: 480px) {

    .data-table .row .key {
        padding-right: 10px;
    }
}