.session-dialog {
    font-family: Arial, Helvetica, sans-serif;
}

.session-dialog > h2 {
    font-size: 2rem;
}

.session-dialog .container {
    margin: 1rem auto;
    height: 80%;
    border: rgba(#000000, 0.3) 1px solid;
    border-top: rgba(#000000, 0.8) 1px solid;
    display: flex;
    flex-direction: column;
}

.session-dialog .container > p {
    margin: 1rem;
    padding: 1rem;
    text-align: center;
}

.container > button {
    margin: 0.5rem 1rem;
    padding: 1rem;
    border-radius: 16px;
    color: #fff;
    background-color: rgba(224, 66, 66, 1);
    font-weight: 700;
}

.session-dialog .container > button:hover {
    opacity: 1.2;
    box-shadow: 3px 3px 8px 3px #000000;
    -webkit-box-shadow: 3px 3px 8px 3px #000000;
}
