.auction-media {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
}

.shadow-1 {
    box-shadow: 0px 2px 6px 2px rgb(0 0 0 / 4%);
}

.auction-media .image {
    width: 100%;
    background-position: 50% 50%;
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    height: 100%;
    overflow: hidden;
}

.auction-media .image img {
    height:auto;
    width: 100%;
    object-fit: contain;
}

.auction-media .image p:not([class*="image-status"]) {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: fit-content;
    background-color: rgba(0, 0, 0, 0.3);
    text-align: center;
    font-size: 1.4rem;
    display: none;
}

.auction-media .unavailable {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 1.8rem;
    display: none;
}

.auction-media .unavailable span {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.auction-media.preview .auction-media-controls {
    padding: 4px 1rem;
    height: fit-content;
}

.offline .auction-media .image {
    background-image: none !important;
}

.offline .auction-media .unavailable {
    display: table;
}

@media only screen and (min-width: 768px) and (orientation: landscape) {
    .auction-media-controls {
        width: 100%;
        height: 100%;
        padding: 8px 16px;
    }

    .auction-media-controls :first-child {
        margin-right: 25px;
    }

    .auction-media-controls > [class*='a-icon'][class*='sound'] {
        float: right;
    }

    .auction-media.shadow-1 {
        margin-bottom: 10px;
    }

    .auction-media.preview {
        margin-bottom: 15px;
        border: 0.5px solid rgb(0 0 0 / 6%);
    }
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .auction-media {
        width: 100%;
        flex-flow: row nowrap;
        border-bottom: 1px solid #a4acb0;
    }

    .auction-media .image {
        margin: 0 auto;
        width: 70%;
    }

    .auction-media-controls {
        width: 22px;
        align-self: flex-end;
    }

    .auction-media .auction-media-controls > a {
        margin: 5px 0 2px 0;
    }

    .auction-media .image img {
        height: 100%;
        width: 100%;
        margin-bottom: 12px;
        object-fit: cover;
    }
}
