@media screen and (max-width: 1024px) and (orientation: portrait) {
    .auto-height-scrollbar-container {
        overflow: hidden auto;
    }

    .auto-height-scrollbar-container > [class*='scrollbar-v'] {
        height: 100% !important;
        max-height: 100% !important;
        padding-bottom: 3rem;
    }
}
