.logout .icon {
    width: 45px;
    height: 100%;
    background-image: url(/img/icon-logout.png);
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-indent: -9999px;
    text-align: left;
}

.logout {
    padding-left: 30px;
    display: table-cell;
    vertical-align: middle;
}


@media only screen and (max-width:1024px) and (orientation:portrait) {
    .logout .icon {
        display: block;
        width: 100%;
        background-color: #fff;
        font-size: 1.8rem;
        color: #004489;
        padding: 8px;
        text-align: center;
        border-radius: 20px;
        border-width: 2px;
        border-style: solid;
        background-image: none;
        text-indent: initial;
    }

    .logout {
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        padding: 0 20px;
    }
}
