.mini-gallery {
    display: flex;
    position: relative;
    margin-bottom: 10px;
    max-width: 290px;

    .sides {
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-end;
        justify-content: space-between;
        max-width: 290px;

        .slide {
            width: 100%;
        }

        .mini-gallery-buttons {
            position: absolute;
            display: flex;
            top: 0;
            width: 100%;
            height: 100%;
            flex-direction: row;
            align-content: flex-end;
            align-items: flex-end;
            padding-bottom: 10px;

            .gallery-button {
                display: flex;
                margin-left: 10px;
                background-color: rgba(0, 0, 0, 0.6);
                color: white;
                border: none;
                padding: 5px 15px;
                border-radius: 5px;
                cursor: pointer;
                box-shadow: 0 0 1px 1px rgb(0, 0, 0, 0.3);
                transition: 0.3s;
                max-height: 25px;
                cursor: pointer;
                z-index: 500;
                user-select: none;

                &:hover {
                    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
                }
            }

            .caption-container {
                display: flex;
                font-size: 14px;
                text-align: right;
                flex-grow: 1;
                justify-content: flex-end;
                margin-right: 10px;

                .caption {
                    color: white;
                    padding: 5px 10px;
                    border-radius: 5px;
                    background-color: rgba(0, 0, 0, 0.6);
                    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.3);
                }
            }
        }

        .mini-gallery-controls {
            position: absolute;
            display: flex;
            top: 0;
            width: 100%;
            height: 100%;
            flex-direction: row;
            align-content: center;
            align-items: center;
            justify-content: space-evenly;
            flex-wrap: nowrap;

            .nav-button {
                display: flex;
                font-size: 20px;
                color: white;
                background-color: rgba(0, 0, 0, 0.6);
                box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
                cursor: pointer;
                padding: 5px;
                border-radius: 5px;
                transition: 0.3s;
                margin-left: 5px;
                user-select: none;

                &:hover {
                    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
                }
            }
            .right {
                left: auto;
                margin-right: 5px;
                margin-left: 0;
            }
            .spacer {
                width: 100%;
            }
        }
    }
}
