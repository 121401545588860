.phenix-video-item {
    position: relative;
    width: 100%;
    height: 100%;
}
.phenix-video-item video {
    height: 80%;
    width: 100%;
    object-fit: contain;
}

.phenix-video-item .controls {
    display: flex;
    justify-content: flex-end;
}

.phenix-video-item .status {
    position: absolute;
    top: 0;
    color: red;
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

@media screen and (max-width: 1000px) {
    .phenix-video-item video {
        height: 100%;
        width: 100%;
        margin: 12px 0;
        object-fit: cover;
    }
}
