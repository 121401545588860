.bid-controls .reserve .label {
    font-family: 'Roboto', Arial, sans-serif;
}

.button {
    font-family: 'Lato','Roboto', Arial, sans-serif;
}

/* Bid Controls */

.bid-controls {
    width: 100%;
    max-width: 340px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 6px;
    line-height: 150%;
}

.bid-controls .stats {
    text-align: right;
    position: relative;
    margin-bottom: 12px;
    margin-right: 12px;
}

.bid-controls .reserve {
    font-size: 1.2rem;
    text-transform: uppercase;
    margin: 0 4px 5px 0;
    opacity: 0.8;
}

.bid-controls .current {
    font-size: 1.6rem;
    font-weight: 600;
}

.bid-controls .status {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 1.4rem;
    color: #dfe0e2;
    padding-left: 2rem;
}

.bid-controls .status.on-sale {
    color: #54a72e;
}

.bid-controls .status:before {
    content: '⬤';
    position: absolute;
    font-size: 2rem;
    top: 0;
    right: 4.8rem;
}

.bid-controls .user-bid {
    display: block;
    text-align: right;
    font-size: 1.4rem;
    padding: 8px 0;
}

.bid-controls .user-bid.highest {
    color: #54a72e;
}

.lozenge {
    border-radius: 20px;
    display: inline-block;
    border-width: 2px;
    border-style: solid;
}

.make-bid.button {
    text-align: center;
    color: #fff;
    background-color: #56a832;
    border-color: #56a832;
    width: 100%;
    font-size: 2.5rem;
    font-weight: bold;
    padding: 12px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.make-bid.button,
.bid-intent.button {
    transition: background-color 0.25s ease, border-color 0.25s ease, color 0.25s ease;
}

.make-bid.button:hover {
    background-color: #46951b;
    border-color: #46951b;
}

.make-bid.button.prepare {
    background-color: #dfe0e2;
    border-color: #dfe0e2;
    cursor: default;
}

.make-bid.button.unauthorised {
    background-color: #a4acb0;
    border-color: #a4acb0;
    cursor: default;
}

.make-bid.button.on-sale {
    background-color: #004489;
    border-color: #004489;
}

.bid-intent.button {
    text-align: center;
    border-color: #e87722;
    color: #e87722;
    font-size: 1.5rem;
    width: 131px;
    padding: 6px 0;
}

.bid-intent.button:hover {
    color: #ce420b;
    border-color: #ce420b;
}

.bid-intent.button.active {
    color: #fff;
    background-color: #e87722;
}

.bid-intent.button.active:hover {
    color: #fff;
    background-color: #ce420b;
}

.bid-intent.button.disabled {
    color: #fff;
    background-color: #a4acb0;
    border-color: #a4acb0;
    pointer-events: none;
    cursor: default;
}

.bid-controls.preview {
    margin-top: 17px;
}

.bid-controls.preview .current {
    font-size: 1.4rem;
}

.bid-controls.preview :not(.no-space-wrap) > .reserve {
    display: block;
}

.bid-controls.preview .stats {
    margin-bottom: 5px;
}

.bid-controls.preview .controls {
    text-align: right;
    position: relative;
}

.bid-controls.preview .make-bid.button {
    max-width: 195px;
    font-size: 2rem;
    padding: 9px;
}

.bid-controls.preview .make-bid.button.secondary-selling {
    max-width: 80%;
}

.bid-controls.preview .bid-intent.button {
    position: absolute;
    top: 6px;
    left: 4px;
    font-size: 1.4rem;
    max-width: 100px;
    padding: 3px 0;
}

.bid-controls.preview .user-bid {
    display: none;
}

.bid-controls .bidders-online-container {
    display: flex;
}

.bid-controls .user-bid {
    font-size: 1.3rem;
}

.intent-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bid-controls .no-space-wrap {
    white-space: nowrap;
}

@media only screen and (max-width: 1600px) {
    .make-bid.button {
        font-size: 1.5rem;
        padding: 8px;
    }

    .bid-intent.button {
        font-size: 1.5rem;
        padding: 3px;
        width: 120px;
    }

    .bid-controls .user-bid {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 1024px) and (orientation: portrait) {

    .bid-controls {
        border-top: 1px solid #dfe0e2;
        margin-bottom: 0;
        max-width: 2000px;
        padding: 2%;
        background-color: #fff;
    }

    .bid-controls > .inner {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }


    .bid-controls .bid-intent.button {
        width: 105px;
    }

    .bid-controls .reserve {
        margin-bottom: 0;
    }

    .bid-controls .stats {
        margin-bottom: 4px;
    }
}

@media only screen and (max-width: 900px) {
    .bid-controls {
        max-width: 100%;
        padding: 2%;
        border-top: 1px solid #dfe0e2;
    }
}
