.list-table {
    display: table;
    width: 100%;
    border-collapse: collapse;
}

.list-table.fixed-layout,
.list-table.all-lots-layout {
    table-layout: fixed;
}

.list-table .row {
    display: table-row;
    border-bottom: 1px solid #edeef0;
    cursor: default;
}

.list-table .row:hover {
    background-color: #fafafa;
}

.list-table .header.row {
    position: sticky;
    top: 0;
    text-transform: uppercase;
    background-color: #f3f3f3;
    z-index: 1;
}

.list-table .col {
    display: table-cell;
    vertical-align: middle;
    padding: 0 6px;
    height: 70px;
}

.list-table .row:not(.header) .multiline-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-table .value-1.multiline-ellipsis {
    max-height: 55px;
    height: auto;
}

.all-lots-layout .col {
    height: 110px;
}


.list-table .header.row .col {
    height: 60px;
    padding: 0 10px 10px 10px;
}

.list-table .col.halign-right {
    text-align: right;
}

.list-table .col.halign-center {
    text-align: center;
}

.list-table .col.valign-middle {
    vertical-align: middle;
}

.list-table .value-1,
.list-table .value-2 {
    display: block;
    padding: 5px 0;
    font-size: 1.4rem;
    font-weight: 500;
    height: 50%;
}

.list-table .value-2 {
    color: #797979;
    margin-top: 2px;
}

.refresh-link {
    display: inline-block;
    padding: 3px 10px;
    color: #fff;
    background-color: #087bbc;
    border-radius: 20px;
}

[class*='sale-history'] .refresh-link {
    font-size: 12px;
}

.list-table .value-1.sold {
    color: green;
    font-weight: bold;
}

.list-table .value-1.unsold {
    color: red;
    font-weight: bold;
}

.list-table .value-1.sold-prov {
    color: orange;
    font-weight: bold;
}

.list-table .value-2.sold {
    color: green;
    font-weight: bold;
}

.list-table .value-2.unsold {
    color: red;
    font-weight: bold;
}

.list-table .value-2.sold-prov {
    color: orange;
    font-weight: bold;
}

/* css for Today's Auction Result-Vendor */

.list-table .header.row .column-height {
    height: 0px;
}

.list-table .row .column-height {
    height: 0px;
    width: 25%;
}

.list-table .row.row-entry {
    border-bottom: 0px;
}

.list-table .row.row-bottom {
    border-bottom: 1px solid lightgray;
}

.list-table .row.row-entry:hover,
.list-table .row.row-bottom:hover {
    background-color: #fff;
}


.list-table .auction-result .value-1 {
    height: 60%;
}

.list-table .auction-result .value-2 {
    height: 40%;
}

.list-table .row.fated-lot {
    background-color: rgba(0, 0, 0, 10%);
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {

    .list-table .value-1,
    .list-table .value-2 {
        font-size: 1.2rem;
    }

    .list-table .header.row .col {
        padding: 0 8px 10px 8px;
    }
}