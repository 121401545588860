/* Gallery Lightbox
---------------------------------------*/

.lightbox {
    z-index: 9999;
    display: flex;
    position: absolute;
    width: 79vw;
    height: 105%;
    top: 0;
    left: 0;
    background-color: rgb(26 55 97 / 80%);
}

.lightbox .contents {
    position: relative;
    z-index: 500;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}

.gallery-carousel {
    max-width: 1120px;
    width: 100%;
    text-align: center;
}

.gallery-carousel .close {
    position: absolute;
    top: 16px;
    right: 1rem;
    color: #fff;
    font-size: 1.8rem;
    padding-left: 30px;
    line-height: 18px;
    background-image: url(img/icon-close-2.png);
    background-repeat: no-repeat;
    background-position: 0 2px;
}

.gallery-carousel .close:hover {
    opacity: 0.8;
}

.gallery-carousel .slides {
    overflow: hidden;
    width: 80%;
    height: 80%;
    margin: 4vh auto 0 auto;
}

.gallery-carousel .slides .track {
    width: 100%;
    height: 100%;
    position: relative;
}

.gallery-carousel .slides .slide {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

.gallery-carousel .description {
    position: relative;
    top: 48px;
    right: 16px;
    width: fit-content;
    background-color: #444;
    color: #fff;
    opacity: 0.2;
    font-size: 2rem;
    padding: 8px 16px;
    border-radius: 16px;
    margin-left: auto;
}

.gallery-carousel .description:hover {
    opacity: 0.7;
}

.gallery-carousel .arrow {
    display: block;
    width: 48px;
    height: 72px;
    position: absolute;
    top: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
}

.gallery-carousel .prev.arrow {
    left: 8px;
    background-image: url(img/gallery-arrow-left.png);
}

.gallery-carousel .next.arrow {
    right: 8px;
    background-image: url(img/gallery-arrow-right.png);
}

.gallery-carousel .thumbnails {
    width: 80%;
    z-index: 600;
    padding: 8px 0;
    background-color: #fff;
    margin: 0 auto;
}

.gallery-carousel .thumbnails .inner {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 1000;
}

.gallery-carousel .thumbnail {
    width: 110px;
    height: 60px;
    display: inline-block;
    vertical-align: middle;
    background-color: #fff;
    margin: 0 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

.gallery-carousel .current.thumbnail {
    height: 80px;
}

@media only screen and (min-width: 1900px) {
    .lightbox {
        width: 77vw;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) and (min-height: 580px) and (max-height: 780px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {    
    .lightbox {
        width: 70vw;
    }
}

