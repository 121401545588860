:root {
    --dark-blue: #004489;
}

.section-not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.text404 {
    font-size: 12rem;
    font-family: 'Lato', 'Roboto', Arial, sans-serif;
    color: var(--dark-blue);
    text-align: center;
}

.btn-go-back {
    margin-top: 6rem;
    font-size: 2rem;
    border: none;
    outline: none;
    cursor: pointer;
    background: none;
}

.btn-go-back:hover {
    text-decoration: underline;
    color: var(--dark-blue);
}

.btn-go-back::before {
    content: "↪";
    display: inline-block;
    font-size: 2.5rem;
    transform: rotate(180deg);
    margin-right: 2px;
}