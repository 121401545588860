.auction-sale-container {
    border-bottom: 1px solid #485f81;
    position: relative;
    transition: background-color 0.3s ease;
    overflow: hidden;
}

    .auction-sale-container > div[class~='control'] {
        position: absolute;
        top: 25px;
        right: 30px;
    }

[class*='flyout'][class*='minimised'] div[class~='control'] {
    position: absolute;
    top: 74px;
    left: 16px;
}

[class*='flyout'][class*='minimised'] .auction-sale-container [class*='auction-preview'] {
    display: none !important;
}

[class*='flyout']:not([class*='minimised']) .auction-sale-container.active {
    background-color: #fff;
    color: #444;
}

[class*='mobile-menu'] .auction-sale-container:first-child {
    border-top: 1px solid #485f81;
}

[class*='mobile-menu'] .auction-sale-container.active {
    background-color: #004489;
}

@media only screen and (max-width:1042px) and (orientation:portrait) {
    [class*='flyout']:not([class*='minimised']) .auction-sale-container.active {
        background-color: #004489;
        color: #fff;
    }
}

@media only screen and (max-width: 768px) {
    [class*='auction-listnew'] .auction-sale-container.active {
        background-color: #004489;
    }
}
