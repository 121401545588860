/* Status Badges (general) */
.status-badge {
    height: 36px;
    display: inline-block;
    vertical-align: top;
    color: #fff;
    text-align: center;
    font-size: 1.4rem;
    position: relative;
    margin-right: 6px;
}

.status-badge .icon,
.status-badge .label {
    display: table-cell;
    vertical-align: middle;
}

.status-badge .icon {
    width: 36px;
    background-color: #fff;
    height: 100%;
    border-radius: 100px;
    border-color: #1a3761;
    border-width: 3px;
    border-style: solid;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.status-badge.type-1 {
    width: 22px;
    border-radius: 100px;
    line-height: 34px;
    background-image: url('/img/help/V5-present.svg');
    background-repeat: no-repeat;
}

.status-badge.no-lot {
    width: 22px;
    border-radius: 100px;
    line-height: 34px;
    background-image: url('/img/help/no-V5-no-lot.svg');
    background-repeat: no-repeat;
}

.status-badge.no-v5 {
    width: 22px;
    border-radius: 100px;
    line-height: 34px;
    background-image: url('/img/help/no-V5.svg');
    background-repeat: no-repeat;
}

.status-badge.type-2,
.status-badge.type-3 {
    background-color: #1a3761;
    border-radius: 100px;
    display: inline-table;
}

.status-badge.type-2 {
    width: 130px;
}

.status-badge.type-3 {
    width: 75px;
}

.status-badge.type-2 .label {
    line-height: 115%;
    text-align: left;
    padding-left: 8px;
}

.status-badge.disabled {
    background-color: #dfe0e2;
}

.status-badge.disabled .icon {
    border-color: #dfe0e2;
}

/* SC Status Badge */

.status-badge-sc .icon {
    background-image: url(/img/icon-sc-white.png);
    background-size: 20px auto;
}

.status-badge-sc.gold .icon {
    background-color: #ec9d00;
}

.status-badge-sc.silver .icon {
    background-color: #a4adb0;
}

.status-badge-sc.bronze .icon {
    background-color: #bc6849;
}

.status-badge-sc.lcv .icon {
    background-color: #fff;
    background-image: url(/img/icon-sc-blue.png);
}

.status-badge-sc.ev .icon {
    background-color: #96c21f;
}

/* MVP Status Badge */

.status-badge-mvp .icon {
    background-image: url(/img/icon-mvp-white.png);
}

.status-badge-mvp .label {
    background-repeat: no-repeat;
    background-position: 35% 50%;
}

.status-badge-mvp.positive .icon {
    background-color: #54a72e;
}

.status-badge-mvp.positive .label {
    background-image: url(/img/icon-check.png);
}

.status-badge-mvp.warning .icon {
    background-color: #e87722;
}

.status-badge-mvp.warning .label {
    background-image: url(/img/icon-exclaim.png);
}

.status-badge-mvp.negative .icon {
    background-color: #fff;
    border-color: #dfe0e2;
}

.status-badge-mvp.negative {
    background-color: transparent;
}

.status-badge-mvp.non-mvp .icon {
    background-color: #1a3761;
}

.status-badge-mvp.non-mvp .label,
.status-badge-mvp.disabled .label {
    background-image: url(/img/icon-checkp.png);
}

.status-badge-mvp.negative .icon,
.status-badge-mvp.disabled .icon {
    background-image: url(/img/icon-mvp-grey.png);
}

/* Smaller versions */

.status-badge.micro {
    height: 22px;
    font-size: 1.1rem;
}

.status-badge.type-1.micro {
    width: 22px;
    line-height: 22px;
}

.status-badge.micro .icon {
    width: 22px;
    background-size: 80%;
    border-width: 0;
}

.status-badge.type-2.micro {
    width: 104px;
}

.status-badge.type-3.micro {
    width: 55px;
}

.status-badge-mvp.micro .label {
    background-size: auto 14px;
}

[class*="grid-main-sale"] .status-badge {
    margin-right: 0;
}

@media only screen and (max-width: 1112px) {
    .status-badge {
        margin-right: 0;
    }
}

@media only screen and (max-width: 1400px) {
    /* Reduce size of status badges in header */

    [class*="grid-main-sale"] .status-badge {
        height: 22px;
        font-size: 1.1rem;
    }

    [class*="grid-main-sale"] .status-badge.type-1, .status-badge.no-lot, .status-badge.no-v5 {
        width: 22px;
        line-height: 22px;
    }

    [class*="grid-main-sale"] .status-badge .icon {
        width: 22px;
        background-size: 80%;
        border-width: 0;
    }

    [class*="grid-main-sale"] .status-badge.type-2 {
        width: 104px;
    }

    [class*="grid-main-sale"] .status-badge.type-3 {
        width: 55px;
    }

    [class*="grid-main-sale"] .status-badge-mvp .label {
        background-size: auto 14px;
    }
}

@media only screen and (min-width: 1401px) {
    [class*="grid-main-sale"] .status-badge.type-1, [class*="grid-main-sale"] .status-badge.no-lot, [class*="grid-main-sale"] .status-badge.no-v5 {
        width: 36px;
        line-height: 22px;
    }
}

/* Help Overlay (status badge tooltips) */

.status-help {
    position: relative;
}

.help-overlay .table {
    margin-top: 20px;
    display: table;
}

.help-overlay .table .col {
    padding-right: 20px;
    padding-bottom: 15px;
    font-weight: 700;
    display: table-cell;
    vertical-align: middle;
}

.help-overlay .table .row:last-child .col {
    padding-bottom: 0;
}

.help-overlay .table .row {
    display: table-row;
}

.help-overlay {
    /*  right: -115px;*/
    z-index: 8000;
    font-size: 1.2rem;
    background-color: #fff;
    border-radius: 15px;
    padding: 25px 30px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 50px;
    width: 575px;
}

.help-overlay.active {
    display: block;
}

.help-overlay .title {
    font-size: 1.6rem;
    font-weight: 600;
    color: #127bbc;
}

.help-overlay .title,
.help-overlay p {
    padding: 0;
    margin: 0;
    margin-bottom: 12px;
}

.help-overlay p:last-child {
    margin-bottom: 0;
}

.help-overlay .images {
    padding: 10px 0;
}

.help-overlay .images img {
    display: inline-block;
    margin-right: 15px;
}

.help-overlay .centered {
    text-align: center;
}

.help-overlay .note {
    font-weight: 700;
    font-size: 1.3rem;
}

/* Close Icon */

.help-overlay .closer a {
    top: 12px;
    right: 15px;
}

.v5-tooltip {
    width: 35px;
}

@media only screen and (max-width: 1400px) {
    .help-overlay {
        position: fixed;
        top: 44%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
    }
}
