.flyout {
    background-color: #1a3761;
    color: #fff;
    position: relative;
    height: 100%;
    border-top-right-radius: 6px;
    transition: width 0.2s ease, min-width 0.2s ease;
}

.scrollbar-v {
    overflow-y: auto;
    max-height: 330px;
    scrollbar-width: thin;
    max-height: 780px;
}

.shadow-2 {
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1);
}

.flyout .body {
    position: relative;
}

/* Todo: Check and remove load-more class if not in use  */
.flyout .load-more {
    display: block;
    color: #fff;
    background-color: #004489;
    font-size: 1.1rem;
    line-height: 100%;
    padding: 8px 10px;
    padding-right: 20px;
    border-radius: 20px;
    text-align: center;
    width: 90px;
    margin-left: auto;
    margin-right: auto;
}

.flyout .load-more {
    background-image: url(img/icon-arrow-down-small.png);
    background-repeat: no-repeat;
    background-position: calc(100% - 8px) 50%;
    position: absolute;
    bottom: 22px;
    left: 50%;
    z-index: 5000;
    margin-left: -45px;
    transition: background-color 0.3s ease;
}

.flyout .load-more:hover {
    background-color: #0e3d73;
}

/* Flyout Panel (Minimised)
---------------------------------------*/

.flyout.minimised,
.side-drawer.minimised {
    width: 110px;
    min-width: 110px;
}

/* Flyout Detail
---------------------------------------*/

.flyout-detail-container {
    position: absolute;
    top: 0;
    left: 367px;
    padding-right: 476px;
    height: 100%;
    z-index: 1000;
    overflow-y: hidden;
    pointer-events: none;
    transition: left 0.2s ease;
}

.flyout-detail {
    display: none;
    width: 456px;
    padding: 10px;
    color: #444;
    background-color: #fff;
    position: absolute;
    top: 20px;
    left: 0;
    pointer-events: all;
    border-left: 1px solid #eee;
}

.flyout.minimised .flyout-detail-container {
    left: 0;
}

.flyout.minimised .flyout-detail {
    display: none !important;
}

.flyout-detail .scrollbar-v {
    height: 360px;
}

.mobile-load-more-wrapper {
    padding: 20px 0;
}

.mobile-load-more {
    background-color: #004489;
    border-color: #004489;
    color: #fff;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 135px;
    padding: 5px 2px;
    text-align: center;
    font-size: 1.4rem;
    margin-bottom: 20px;
    margin-top: 20px;
}

.side-drawer.minimised {
    left: 0;
}

.side-drawer.minimised {
    /* display: none !important; */
}

.side-drawer .body {
    overflow: auto;
    max-height: 90%;
}

.side-drawer .footer {
    padding-bottom: 55px;
}

.side-drawer .buttons {
    padding: 0 20px;
}

.side-drawer .options {
    padding: 10px 0;
    display: none;
}

.side-drawer .option {
    text-align: center;
    padding: 10px;
}

.side-drawer .option .label {
    font-size: 1.5rem;
}

.text-center {
    text-align: center;
    margin-top: 5px;
}

.mobile-menu-viewport {
    height: 70%;
}


.side-drawer {
    display: table-cell;
    vertical-align: top;
    height: 100%;
    position: relative;
    min-width: 375px;
    background-color: #1a3761;
    color: #fff;
    position: relative;
    height: 100%;
    border-top-right-radius: 6px;
    transition: width 0.2s ease, min-width 0.2s ease;
    box-shadow: 0 2px 6px 2px rgb(0 0 0 / 10%);
}

.mobile-menu-viewport {
    height: auto;
}


@media screen and (max-width: 1024px) and (orientation: portrait),
(max-width: 1024px) and (max-aspect-ratio: 13/9) {

    .side-drawer {
        box-shadow: 0 2px 6px 2px rgb(0 0 0 / 10%);
        min-width: 300px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10000;
        color: #fff;
        display: block;
        bottom: 0;
    }

    .hide-sidebar {
        display: none;
    }

}

@media only screen and (max-width: 1210px) and (orientation: portrait) {
    .side-drawer .body {
        max-height: 82%;
    }
}

@media only screen and (max-width: 1210px) and (orientation: landscape) {
    .side-drawer .body {
        max-height: 80%;
    }
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
    .side-drawer .body {
        max-height: 75%;
    }
}