.dialog-overlay {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 10001; //Required to be this high to account for mobile sales list
}

.dialog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(#000000, 0.5);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0vh;
}

.dialog-content {
    background-color: rgb(240, 240, 240);
    width: 30vw;
    padding: 3rem;
    border: none 0px #fff;
    border-radius: 15px;
    box-shadow: 5px 5px 15px 5px #000000;
    -webkit-box-shadow: 5px 5px 15px 5px #000000;
    font-family: 'Lato', 'Roboto', Arial, sans-serif;
    font-size: 1.8rem;
    position: relative;
}

.close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 2rem;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.5);
}

.close-btn:hover {
    color: rgba(0, 0, 0, 1);
}

@media only screen and (max-width: 1000px) {
    .dialog-content {
        width: 60%;
    }
}
