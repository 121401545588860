/* Colours
---------------------------------------
* Text Grey:   #444444
* Blue:        #004489
* Dark Blue:   #1A3761
* Light Blue:  #087BBC
---------------------------------------*/

/* General
---------------------------------------*/

* {
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    height: 100vh;
    padding: 0;
    margin: 0;
    background: #fff;
}

html {
    font-size: 62.5%;
}

body, body.active {
    visibility: visible;
}

a {
    text-decoration: none;
}

html,
body,
a {
    color: #444444;
}

p,
ul,
ol {
    padding: 0;
    margin: 0;
}

ul,
ol {
    list-style-type: none;
}

::placeholder {
    color: #000;
    opacity: 1;
}

:-ms-input-placeholder {
    color: #000;
}

::-ms-input-placeholder {
    color: #000;
}

/* Scrollbars
---------------------------------------*/

.scrollbar-v {
    overflow-y: auto;
}

/* Fonts
---------------------------------------*/

html,
body {
    font-family: 'Lato', Arial, sans-serif;
}

/*  Icon Text/Links 
    TO Do - Will require this in future for watchlist icon in upcoming vehicles
 

.icon-text {
    padding-top: 3px;
    line-height: 20px;
    display: inline-block;
    text-transform: uppercase;
    color: #087bbc;
    background-position: 0 50%;
    background-repeat: no-repeat;
    font-size: 1.25rem;
    font-weight: 600;
    padding-left: 28px;
}

.icon-text.inactive {
    color: #a4acb0;
}
  
*/

/* Below css from simulcast-mobile file*/

body {
    overflow: hidden !important;
    top: 0;
}

.orientation-error {
    display: table;
    width: 100%;
    height: 100%;
    color: #000;
    position: fixed;
}

    .orientation-error > div {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        font-size: 2rem;
    }


input[type='text'],
input[type='password'] {
    font-size: 16px;
}

@media only screen and (min-width: 770px) {
    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        background-color: #dfe0e2;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #a4acb0;
    }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    html {
        overscroll-behavior: none !important;
        overflow-y: scroll !important;
    }
}

/*--------Ipad pro 12.09 (landscape) ----*/
@media only screen and (min-width: 1366px) and (max-height: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape) {
    html {
        overflow-y: scroll !important;
    }
}

@supports (-webkit-touch-callout: none) {
    @media only screen and (min-width: 1366px) and (max-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
        body {
            overflow-y: scroll !important;
        }
    }
}
