

.scrollbar-v {
    overflow-y: auto;
}

.scrollbar-v-tabs {
    max-height: 630px;
    padding-bottom: 170px;
}
.scroll {
    height: 32vh;
}
