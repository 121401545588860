.auction-preview {
    position: relative;
    padding: 20px 27px;
    font-size: 1.4rem;
    color: #7e838b;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1);
}

.auction-preview .head {
    width: 220px;
    margin-bottom: 15px;
}

.auction-preview .head .head-p {
    font-size: 1.4rem;
    line-height: 19px;
}

.auction-preview .body {
    width: 318px;
}

.auction-preview .title-2 {
    font-size: 1.4rem;
    margin-bottom: 8px;
    font-weight: 600;
}

.auction-preview .stats {
    font-size: 1.1rem;
    margin-bottom: 15px;
}

.auction-preview .stat, .badge-list {
    display: inline-block;
    padding-left: 15px;
    position: relative;
    margin-bottom: 5px;
}

.badge-list {
    vertical-align: middle;
    margin-bottom: 0;
}

.auction-preview .stat {
    padding-left: 4px;
}

.auction-preview .stat:before {
    content: '-';
    padding-right: 4px;
}

.auction-preview .stat:first-child::before {
    padding: 0;
    content: '';
}

.auction-preview .stat.no-sep:before {
    content: '';
}

.auction-preview .auction-preview-minimise {
    position: absolute;
    top: 14px;
    right: 90px;
}

.cells {
    display: table;
    width: 100%;
}

.cell {
    display: table-cell;
    vertical-align: middle;
}

.cell.right {
    text-align: right;
}

.auction-preview .status-badges {
    margin-bottom: 12px;
}

.secondary-vendor-logo {
    height: 30px;
    width: 20px;
    margin-right: 6px;
    background-repeat: no-repeat;
    background-position: 50%;
}

.bid-detail-trigger {
    background-image: url(/img/icon-bid-detail.png);
    background-position: 0 50%;
    background-repeat: no-repeat;
    padding-left: 22px;
    line-height: 16px;
    display: inline-block;
}

.auction-preview.w-detail .bid-detail-trigger:before {
    content: 'Close ';
}

/* pop out and swap icon*/
.a-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    position: relative;
}

.a-icon.ac-popout,
.a-icon.ac-swap {
    margin-left: 10px;
}

.a-icon.ac-popout .decal,
.a-icon.ac-swap .decal {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    z-index: 50;
}

.a-icon.ac-popout .a,
.a-icon.ac-swap .a {
    position: absolute;
    display: block;
    background-repeat: no-repeat;
    transition: left 0.3s ease, transform 0.3s ease;
}

.a-icon.ac-popout {
    z-index: 100;
}

.a-icon.ac-swap {
    z-index: 500;
}

.a-icon.ac-popout .decal {
    background-image: url(/img/icon-a-popup.png);
}

.a-icon.ac-popout .a {
    top: 0;
    right: 1px;
    width: 12px;
    height: 12px;
    background-image: url(/img/icon-a-popup-arrow.png);
}

.a-icon.ac-popout:hover .a {
    transform: translate(2px, -2px);
}

.a-icon.ac-swap .decal {
    background-image: url(/img/icon-a-swap.png);
    width: 38px;
}

.a-icon.ac-swap .a {
    top: 50%;
    left: 2px;
    width: 23px;
    height: 13px;
    background-image: url(/img/icon-a-swap-arrow.png);
    margin-top: -7px;
}

.a-icon.ac-swap:hover .a {
    left: 6px;
}

.a-icon .tooltip {
    display: block;
    font-size: 1.2rem;
    font-weight: 600;
    position: absolute;
    z-index: 20;
    background-color: #fff;
    white-space: nowrap;
    padding: 0px 12px;
    border-radius: 20px;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1);
}

.a-icon .tooltip {
    opacity: 0;
    transition: left 0.3s ease, opacity 1s ease, padding 0.25s ease;
    visibility: hidden;
    pointer-events: none;
}

.a-icon.ac-popout .tooltip,
.a-icon.ac-swap .tooltip {
    padding-right: 40px;
    top: -5px;
    right: -7px;
    text-align: right;
}

.a-icon:hover .tooltip {
    opacity: 1;
    visibility: visible;
    padding-top: 4px;
    padding-bottom: 4px;
}

.auction-preview .head p p {
    display: none;
}

.mot-icon {
    width: 86px;
    height: 22px;
    font-size: 1.1rem;
    background-color: #1a3761;
    border-radius: 100px;
    display: inline-table;
    background-size: 20%;
    border-width: 0;
    border-radius: 100px;
    border: 3px solid #1a3761;
    background-repeat: no-repeat;
    background-position: 0 50%;
    display: table-cell;
    vertical-align: middle;
    color: white;
    background-image: url(/img/mot/mot.svg);
}

.mot-icon-positive {
    left: 25%;
    position: relative;
}

@media only screen and (max-width: 1024px) {
    .auction-preview .auction-preview-minimise {
        display: none;
    }

    .auction-preview .body {
        display: none;
    }

    .auction-preview .cells {
        display: none;
    }

    .auction-preview .head {
        padding: 21px 0px 4px 12px;
        color: #fff;

        p p {
            font-size: 11px;
            display: block;
        }
    }

    .auction-preview {
        padding: 0px;
        box-shadow: none;
    }
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .sale-header-desc {
        max-width: 240px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
    }
}
