 .upcoming-vehicles {
    height: 485px;
    padding: 10px 15px;
    max-height: 300px;
    overflow-y: auto;
}

.upcoming-vehicles-container {
    position: fixed;
    display: table-cell;
    bottom:10px;
    width:50%;
}

.auction-preview:first-child {
    padding-top: 0;
}

.auction-preview .title-2,
.auction-preview .stats {
    margin-bottom: 5px;
}


.auction-preview {
    position: relative;
    padding: 10px 0;
    border-bottom: 1px solid #f2f2f3;
    font-size: 1.2rem;
    color: #7e838b;
} 

.auction-preview .title-2 {
    font-size: 1.4rem; 
    font-weight: 600;
}

.auction-preview .stats {
    font-size: 1.2rem; 
}

.auction-preview .stat {
    display: inline-block;
    padding-left: 22px;
    position: relative;
    margin-bottom: 5px;
}

.auction-preview .stat:before {
    content: '-';
    position: absolute;
    bottom: 0;
    left: 10px;
}

.auction-preview .stat:first-child {
    padding-left: 0;
    content: '';
}

.auction-preview .stat.no-sep:before {
    content: '';
} 

.cells {
    display: table;
    width: 100%;
}

.cell {
    display: table-cell;
    vertical-align: middle;
}

@media only screen and (min-width: 1024px) and (max-width: 1024px) and (min-height: 690px) and (max-height: 700px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) { 
    .upcoming-vehicles-container {
        left: 14%;
    }
}
 