.center {
    height: 60vh;
    position: relative;
}

.select-role {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    border: 3px solid green;
    padding: 5% 5%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.lozenge {
    border-radius: 20px;
    display: inline-block;
    border-width: 2px;
    border-style: solid;
}


.input-button {
    width: 100%;
    color: #fff;
    font-weight: 600;
    background-color: #56a832;
    border-color: #56a832;
    line-height: 36px;
    font-size: 2.2rem;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.input-button:hover {
    background-color: #46951b;
    border-color: #46951b;
}

@media only screen and (max-width: 768px)
{
    .select-role
    {
        width: fit-content;
        max-width: 80%;
        text-align: center;
    }
}