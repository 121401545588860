.min_max_icon 
{
	display: inline-block;
	height: 24px;
	width: 24px;
	position: relative;
  border-radius: 3px;
  border-width: 2px;
  margin-top: 2px;
	cursor: pointer;
}

.min_max_icon 
{
  border-color: #087bbc;
  border-style: solid;
}

.min_max_icon .d 
{
  width: 10px;
  height: 2px;
  background-color: #087bbc;
  position: absolute;
  top: 50%;
  left: 5px;
  margin-top: -1px;
  transition: width 0.3s ease;
}

.min_max_icon .a 
{
  position: absolute;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #087bbc;
  display: none;
}

.min_max_icon .a 
{
  transition: top 0.3s ease, bottom 0.3s ease, left 0.3s ease, right 0.3s ease;
}

.min_max_icon .a_1 
{
  top: 3px;
  right: 1px;
  transform: rotate(45deg);
}

.min_max_icon .a_2 
{
  bottom: 3px;
  left: 1px;
  transform: rotate(-135deg);
}

.minimised .min_max_icon .d,
.expander .min_max_icon .d 
{
  display: none;
}

.minimised .min_max_icon .a,
.expander .min_max_icon .a 
{
  display: block;
}

.expander.expanded .min_max_icon .d 
{
  display: block;
}

.expander.expanded .min_max_icon .a 
{
  display: none;
}

.min_max_icon:hover .d,
.min_max_icon.hover .d 
{
  width: 7px;
}

.min_max_icon:hover .a_1,
.min_max_icon.hover .a_1 
{
  top: 0;
  right: -2px;
}

.min_max_icon:hover .a_2,
.min_max_icon.hover .a_2 
{
  bottom: 0;
  left: -2px;
}

.min_max_icon.white 
{
  border-color: #fff;
}

.min_max_icon.white .a 
{
  border-bottom: 5px solid #fff;
}

.min_max_icon.white .d 
{
  background-color: #fff;
}