.lookers-overlay {
    position: fixed;
    top: 12px;
    width: 50vw;
    margin: 24px 25%;
    z-index: 10001;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.lookers-message {
    border: 1px #000 solid;
    border-radius: 5px;
    padding: 8px;
    text-align: center;
    background-color: rgba(221, 8, 8, 0.8);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
}

.lookers-message:hover {
    background-color: rgba(221, 8, 8, 1);
    -webkit-box-shadow: 4px 4px 8px 4px #000000;
    box-shadow: 4px 4px 8px 4px #000000;
    cursor: pointer;
}

.lookers-message:active {
    animation: pulse 2s infinite;
}

.rounded-border-transfer-div {
    border: 1px solid #000;
    border-radius: 8px;
    padding: 1rem 2rem;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.lookers-message > p {
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
}

/* Login Screen
---------------------------------------*/

.content-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1a3761;
    z-index: 9000;
}

.inset.box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.inset.box {
    display: inline-block;
    text-align: center;
    width: 90%;
    max-width: 574px;
    background-color: #fff;
}

.inset.box > .inner {
    width: 90%;
    max-width: 358px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2px;
}

.inset.box [class*="heading-component"] {
    margin: 18px auto 30px auto;
}

.inset.box .session-transferred-div [class*="heading-component"] {
    margin: 1.4rem auto;
}

.inset.box .row {
    margin-bottom: 15px;
}

.inset.box .options {
    text-align: right;
    padding: 20px 0;
}

.inset.box .options .link {
    font-size: 1.2rem;
    color: #b1b1b0;
    display: block;
    text-decoration: underline;
    text-decoration-color: #00e;
}

.inset.box .promo {
    width: 100%;
    text-align: center;
    color: #fff;
    padding: 20px 10px;
    background-color: #004489;
}

.inset.box .promo .title {
    font-size: 1.8rem;
    padding: 0;
    margin: 0;
    margin-bottom: 15px;
    font-weight: 400;
}

.inset.box .promo .cta {
    width: 100%;
    max-width: 235px;
    border-color: #fff;
    color: #fff;
    background-color: #004489;
    font-size: 1.5rem;
    padding: 5px;
    transition: background-color 0.3s ease;
}

.inset.box .promo .cta:hover {
    background-color: #1a3761;
}

.input-text {
    width: 100%;
    font-size: 1.5rem;
    outline: none;
    border: 1px solid #e5e6e7;
    padding: 11px;
    color: #444444;
}

.input-button {
    width: 100%;
    color: #fff;
    font-weight: 600;
    background-color: #56a832;
    border-color: #56a832;
    line-height: 36px;
    font-size: 2.2rem;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.input-button:hover {
    background-color: #46951b;
    border-color: #46951b;
    cursor: pointer;
}

.span-view-password-icon {
    position: absolute;
    top: 35%;
    right: 14px;
    cursor: pointer;
}

.eye-view-icon,
.eye-hide-icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-image: url(/img/icon-watch.png);
    background-position: center;
    background-repeat: no-repeat;
}

.eye-hide-icon::before {
    content: '/';
    font-size: 18px;
    font-weight: 700;
    color: #4fb0e5c4;
    line-height: 15px;
}

.password {
    position: relative;
}

.lozenge {
    border-radius: 20px;
    display: inline-block;
    border-width: 2px;
    border-style: solid;
}

.input-button-transparent {
    @extend .input-button;
    background-color: transparent;
    border-color: #004489;
    color: #004489;
}

.input-button-transparent:hover {
    background-color: transparent;
    border-color: #004489;
}

.transfer-arrows {
    font-size: 4rem;
}

@media only screen and (max-device-width: 960px) and (orientation: landscape) {
    .content-overlay {
        position: fixed;
    }
}

@media only screen and (max-width: 600px) {
    .session-transferred-div .input-button {
        font-size: 2rem;
    }
}