.accordion {
    margin-bottom: 18px;
}

.header-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-transform: uppercase;
    font-size: 1.4rem;
    background-color: #f2f2f2;
    height: 45px;
    padding: 0 10px;
    cursor: pointer;
    letter-spacing: 0.28px;
}

.content {
    padding: 0 1rem;
    height: 0;
    overflow: hidden;
    transition: height 3s ease;
}

.expanded .content {
    height: auto;
    min-height: 50px;
}

.a-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    position: relative;
}

.d {
    border-top: 2px solid #444444;
    border-color: #444444;
    height: 0;
    width: 14px;
    position: absolute;
    top: 50%;
    left: 5px;
    transition: border-color 0.3s ease, transform 0.2s ease;
}

.d-2 {
    transform: rotate(90deg);
}

.a-icon.expand:hover .d,
.a-icon.expand.hover .d {
    border-color: #127bbc;
}

.expanded .a-icon .d {
    transform: rotate(180deg);
}

.a-icon .arrow-icon {
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
    transform: rotate(270deg);
}

.expanded .a-icon .arrow-icon {
    transform: rotate(90deg);
}

.overlay-box {
    padding: 12px;
    background-color: #fff;
    border-radius: 5px;
    width: 250px;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
    margin-left: 0;
}

@media only screen and (max-width: 1200px) {
    .expander .scrollbar-v {
        padding: 0 8px;
        max-height: 1000px;
    }

}

@media only screen and (max-width: 768px) {
    .header-bar {
        height: 30px;
        margin-top: 8px;
    }
}