.a-icon 
{
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    position: relative;
    background-repeat: no-repeat;
    background-position: 0% 0;
}

.a-icon.active
{
    background-position: 100% 0%;
}

/* Images */

.a-icon.video 
{
    background-image: url('img/icon-a-camera.png');
}

.a-icon.picture 
{
    background-image: url('img/icon-a-picture.png');
}

.a-icon.sound 
{
    background-image: url('img/sound-off.svg');
}

.a-icon.sound.active 
{
    background-image: url('img/sound-on.svg');
}