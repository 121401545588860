.history {
    padding: 0 0;
}

.history li {
    font-size: 16px;
    padding-left: 30px;
    background-repeat: no-repeat;
    background-position: 5px 2px;
    margin-bottom: 15px;
    position: relative;
    min-height: 21px;
}

.history li.bid {
    font-size: 1.2rem;
    color: #797979;
    background-image: none;
    margin-bottom: 8px;
}

.history li.grouped {
    background-image: url(img/timeline/line.png);
    background-repeat: no-repeat;
    background-position: 13px;
    margin-bottom: 0px;
    padding-bottom: 15px;
}

.history li.bid.for {
    color: #4fb0e5;
    font-weight: 600;
}

.history li.bid.warning {
    color: #c83c3c;
    font-weight: 600;
    font-size: 1.2rem;
}

.history li.bid.auctioneer {
    color: #c83c3c;
    font-weight: 600;
    list-style-type: disc;
    padding-left: 45px;
}

.history li.bid.auctioneer:before {
    content: '\2022';
    position: absolute;
    top: 0;
    left: 25px;
}

.history li.bid .amount {
    padding-right: 20px;
}

.history li .title {
    text-transform: uppercase;
}

.history li.play {
    color: #54a72e;
    background-image: url(img/timeline/start.png);
}

.history li.pause {
    color: #797979;
    background-image: url(img/timeline/pause.png);
}

.history li.message {
    color: #087bbc;
    background-image: url(img/timeline/message.png);
}

.history li.timing {
    color: #004489;
    background-image: url(img/timeline/timing.png);
}

.history li.new-lot {
    color: #004489;
    background-image: url(img/timeline/new.png);
}

.history li.sold {
    color: #54a72e;
    background-image: url(img/timeline/hammer-sold.png);
}

.history li.sold-provisional {
    color: #127bbc;
    background-image: url(img/timeline/hammer-provisional.png);
}

.history li.not-sold {
    color: #444444;
    background-image: url(img/timeline/hammer-not-sold.png);
}

.history li.announcement {
    color: #54a72e;
    background-image: url(img/timeline/announcement.png);
}

.history li.remark {
    color: #ec9d00;
    background-image: url(img/timeline/remark.png);
}

.history li.reconnect {
    color: #fff;
    background-color: #56a832;
    padding-top: 5px;
    padding-right: 20px;
    padding-bottom: 8px;
    border-radius: 3px;
    background-image: url(img/timeline/reconnect.png);
    background-position: 5px 7px;
}

.history li.disconnect {
    color: #fff;
    background-color: #c83c3c;
    padding-top: 5px;
    padding-right: 20px;
    padding-bottom: 8px;
    border-radius: 3px;
    background-image: url(img/icon-offline.png);
    background-position: 5px 7px;
}

.timeline {
    padding: 12px;
    position: relative;
}

.scrollbar-v {
    overflow-y: auto;
    max-height: 330px;
    scrollbar-width: thin;
}

.history .scrollbar-v {
    position: relative;
    display: block;
    word-break: break-word;
}

.span-bid-type-image {
    position: absolute;
    left: 4px;
    width: 20px;
    height: 20px;
    background-size: contain;
}

.manhiem-bid {
    background-image: url(img/manheim_bid.svg);
}

.hall-bid {
    background-image: url(img/hall_bid.svg);
}

.internet-bid {
    background-image: url(img/online_bid.svg);
}

@media only screen and (max-device-width: 1024px) and (orientation : portrait) {
    .history .scrollbar-v {
        overflow-y: auto;
        max-height: none;
    }
}


/*-----Samsung Galaxy Tab s4 tab landscape---*/
@media only screen and (min-device-width: 800px) and (max-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 2)and (orientation : landscape) {

    .history .scrollbar-v {
        overflow-y: auto;
    }
}

.timeline {
    box-shadow: 0 2px 6px 2px rgb(0 0 0 / 4%);
}

.timeline .unavailable {
    padding: 8px 10px 10px 35px;
    color: #fff;
    font-size: 1.6rem;
    position: absolute;
    top: 40px;
    left: 0;
    background-color: #c83c3c;
    margin: 12px;
    border-radius: 4px;
    z-index: 2;
}

.timeline .unavailable {
    background-image: url(img/icon-offline.png);
    background-repeat: no-repeat;
    background-position: 12px 10px;
    display: block;
}

@media only screen and (max-width:1025px) and (orientation:portrait) {
    .timeline {
        box-shadow: none;
        padding: 0;
        padding: 3%;
    }

    .timeline .unavailable {
        padding: 3px 2px 8px 27px;
        font-size: 1.5rem;
        position: unset;
        margin: 12px;
        border-radius: 4px;
        background-position: 6px 6px;
        top: 0;
        left: 0;
    }
}

/* Bid Detail Header
---------------------------------------*/

.bid-detail-header {
    width: 100%;
    height: 40px;
    background-color: #f2f2f2;
    padding: 0 10px;
    font-size: 1.4rem;
    margin-bottom: 14px;
    display: table;
}

.bid-detail-header .title {
    text-transform: uppercase;
    display: table-cell;
    vertical-align: middle;
}

.bidder-number {
    text-align: right;
    display: table-cell;
    vertical-align: middle;
}