.winninglot-container {
    display: flex;
    align-items: baseline;
    border-bottom: 1px solid grey;
    margin-bottom: 2px;
    height: 120px;
}

.winninglot-container .pricing {
    display: flex;
    justify-content: space-between;
}

.scroll-container {
    overflow-y: auto;
}

.pricing span:first-child {
    font-weight: bold;
}

.heading-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 8px;
    flex-grow: 1;
}

.img-container .lot-label {
    color: #004489;
    font-weight: bold;
}

.heading {
    word-break: break-word;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.img-container {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 4px;
    float: left;
}

.img-container div {
    margin: 2px 0px;
}

.img-container div img {
    width: 78px;
    border: 1px solid black;
    min-height: 55px;
}

.heading span {
    margin-left: 5px;
    color: gray;
    font-size: 13px;
}
