.overlay-main {
    right: 0px;
    width: 100%;
}

.drop-down {
    border: 1px solid black;
}

.refresh-button {
    display: inline-block;
    padding: 3px 10px;
    color: #fff;
    background-color: #087BBC;
    border-color: #087BBC;
    border-radius: 20px;
}

.overlay-main .content .column-height {
    width: 25%;
}

.help-overlay {
    position: absolute;
    top:50px;
    z-index: 8000;
    font-size: 1.2rem;
    background-color: #fff;
    border-radius: 15px;
    padding: 25px 30px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.options-menu {
    position: relative;
    padding-right: 20px;
    padding-left: 25px;
}

@media screen and (max-width: 1400px) {
    .help-overlay {      
        top: 44vh;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}

@media screen and (max-width: 1366px) and (max-height: 1024px) and (min-height : 910px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .help-overlay { 
        top: 34vh;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}

@media screen and (max-width: 1300px) {
    .help-overlay { 
        top: 42vh;
        overflow: auto;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}

@media screen and (max-width: 1200px) {
    .help-overlay { 
        top: 38vh;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}
