.user-role {
    position: fixed;
    z-index: 10000;
    left: 10px;
    bottom: 10px;
    color: white;
    text-align: center;
    text-transform: capitalize;
}

@media only screen and (max-width:1024px) and (orientation:portrait) {
    .user-role {
        position: absolute;
        text-align: right;
        text-transform: inherit;
        top: 1.2%;
        right: 4%;
        left: auto;
        height: 2%;
    }
}
